<template>
  <page-component :loading="loading" class="agent-data-page">
    <template #content>
      <v-sheet style="border-radius: 4px" :class="{ sticky: active }" id="nav">
        <div style="display: flex; justify-content: center; align-items: center">
          <v-tabs
            id="nav-container"
            :class="toggleNavClass()"
            color="transparent"
            center-active
            icons
            class="d-flex align-center justify-center py-2"
            v-model="tab"
            style="height: 52px !important"
          >
            <v-tab
              replace
              :style="
                $route.name == 'agent_data'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'agent_data' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.212"
                height="22.212"
                viewBox="0 0 24.212 24.212"
                class="ml-2"
              >
                <g
                  id="_Group_user"
                  data-name="&lt;Group&gt;user"
                  transform="translate(0.5 0.5)"
                >
                  <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="6.462"
                    cy="5.816"
                    rx="6.462"
                    ry="5.816"
                    transform="translate(5.117)"
                    fill="none"
                    :stroke="$route.name == 'agent_data' ? '#fff' : '#2c2c2c'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="_Path_2"
                    data-name="&lt;Path&gt;"
                    d="M13.106,14.5a20.714,20.714,0,0,0-9.185,2.432A4.421,4.421,0,0,0,1.5,20.878v.807a1.658,1.658,0,0,0,1.658,1.658h19.9a1.658,1.658,0,0,0,1.658-1.658v-.807a4.421,4.421,0,0,0-2.421-3.946A20.714,20.714,0,0,0,13.106,14.5Z"
                    transform="translate(-1.5 -0.131)"
                    fill="none"
                    :stroke="$route.name == 'agent_data' ? '#fff' : '#2c2c2c'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </svg>
              <span v-if="$route.name == 'agent_data'">بيانات العميل</span>
            </v-tab>
            <v-tab
              replace
              :style="
                $route.name == 'real_estate_data'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'real_estate_data' }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.891"
                height="24.212"
                class="ml-2"
                viewBox="0 0 21.891 24.212"
              >
                <g
                  id="Icon_feather-home"
                  data-name="Icon feather-home"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_3977"
                    data-name="Path 3977"
                    d="M4.5,11.124,14.945,3l10.445,8.124V23.891a2.321,2.321,0,0,1-2.321,2.321H6.821A2.321,2.321,0,0,1,4.5,23.891Z"
                    transform="translate(-4.5 -3)"
                    fill="none"
                    :stroke="$route.name == 'real_estate_data' ? '#fff' : '#2c2c2c'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_3978"
                    data-name="Path 3978"
                    d="M13.5,29.606V18h6.964V29.606"
                    transform="translate(-6.536 -6.394)"
                    fill="none"
                    :stroke="$route.name == 'real_estate_data' ? '#fff' : '#2c2c2c'"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </svg>
              <span v-if="$route.name == 'real_estate_data'">بيانات العقار</span>
            </v-tab>
            <v-tab
              replace
              :style="
                $route.name == 'fund_data'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'fund_data' }"
            >
              <svg
                class="ml-2"
                id="finance-report"
                xmlns="http://www.w3.org/2000/svg"
                width="20.223"
                height="23.212"
                viewBox="0 0 20.223 23.212"
              >
                <path
                  id="Path_2766"
                  data-name="Path 2766"
                  d="M73.167,28.241a.357.357,0,0,0-.256-.108H63.947a1.887,1.887,0,0,0-1.885,1.885v1.694h-2.7A1.887,1.887,0,0,0,57.478,33.6V49.46a1.887,1.887,0,0,0,1.885,1.885h11.87a1.887,1.887,0,0,0,1.885-1.885V47.766h2.7A1.887,1.887,0,0,0,77.7,45.882V33.057a.356.356,0,0,0-.1-.248Zm.1,1.128L76.5,32.7h-2.76a.474.474,0,0,1-.474-.474ZM72.4,49.461a1.173,1.173,0,0,1-1.171,1.171H59.363a1.173,1.173,0,0,1-1.171-1.171V33.6a1.173,1.173,0,0,1,1.171-1.171h2.7V45.882a1.887,1.887,0,0,0,1.885,1.885H72.4v1.694Zm3.413-2.408H63.947a1.173,1.173,0,0,1-1.171-1.171V30.018a1.173,1.173,0,0,1,1.171-1.171h8.608v3.38a1.188,1.188,0,0,0,1.187,1.187h3.246V45.882a1.173,1.173,0,0,1-1.171,1.171Z"
                  transform="translate(-57.478 -28.133)"
                  :fill="$route.name == 'fund_data' ? '#fff' : '#2c2c2c'"
                />
                <path
                  id="Path_2767"
                  data-name="Path 2767"
                  d="M207.593,110.417h-9.556a.357.357,0,0,0,0,.713h9.556a.357.357,0,1,0,0-.713Zm-9.556-1.525h5.721a.357.357,0,1,0,0-.713h-5.721a.357.357,0,0,0,0,.713Zm2.875-3.808h-.03a1.173,1.173,0,0,1-1.157-1.171.357.357,0,0,0-.713,0,1.887,1.887,0,0,0,1.528,1.85v.587a.357.357,0,1,0,.713,0v-.587a1.885,1.885,0,0,0-.357-3.735,1.171,1.171,0,1,1,1.171-1.171.357.357,0,1,0,.713,0,1.887,1.887,0,0,0-1.528-1.85V98.49a.357.357,0,0,0-.713,0v.517a1.885,1.885,0,0,0,.357,3.735,1.171,1.171,0,1,1,.015,2.343Z"
                  transform="translate(-190.539 -94.568)"
                  :fill="$route.name == 'fund_data' ? '#fff' : '#2c2c2c'"
                />
              </svg>
              <span v-if="$route.name == 'fund_data'">بيانات التمويل</span>
            </v-tab>
            <v-tab
              :disabled="!for_agent"
              replace
              :style="
                $route.name == 'fund_calc'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'fund_calc' }"
            >
              <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="23.212"
                height="23.212"
                viewBox="0 0 23.212 23.212"
              >
                <path
                  id="calculator"
                  d="M5.748,7.858H6.8V8.913h0a.527.527,0,0,0,.528.527h0a.527.527,0,0,0,.527-.528V7.858H8.913a.528.528,0,1,0,0-1.055H7.858V5.748a.528.528,0,0,0-1.055,0V6.8H5.748a.528.528,0,0,0,0,1.055Zm.837,10.023L5.466,19a.527.527,0,1,0,.746.746l1.119-1.119L8.45,19.746A.527.527,0,0,0,9.2,19L8.076,17.881,9.2,16.762l.008-.008a.527.527,0,0,0-.754-.738L7.331,17.135,6.212,16.017,6.2,16.009a.527.527,0,1,0-.738.754ZM19.464,6.8H16.3a.528.528,0,0,0,0,1.055h3.165a.528.528,0,0,0,0-1.055ZM16.3,16.826h3.165a.528.528,0,0,0,0-1.055H16.3a.528.528,0,0,0,0,1.055ZM21.047,1H4.165A3.169,3.169,0,0,0,1,4.165V21.047a3.169,3.169,0,0,0,3.165,3.165H21.047a3.169,3.169,0,0,0,3.165-3.165V4.165A3.169,3.169,0,0,0,21.047,1ZM12.078,23.157H4.165a2.113,2.113,0,0,1-2.11-2.11V13.134H12.078Zm0-11.078H2.055V4.165a2.113,2.113,0,0,1,2.11-2.11h7.913Zm11.078,8.968a2.113,2.113,0,0,1-2.11,2.11H13.134V13.134H23.157Zm0-8.968H13.134V2.055h7.913a2.113,2.113,0,0,1,2.11,2.11ZM16.3,19.992h3.165a.528.528,0,0,0,0-1.055H16.3a.528.528,0,1,0,0,1.055Z"
                  transform="translate(-1 -1)"
                  :fill="$route.name == 'fund_calc' ? '#fff' : '#2c2c2c'"
                />
              </svg>
              <span v-if="$route.name == 'fund_calc'">حاسبة التمويل</span>
            </v-tab>
            <v-tab
              replace
              :style="
                $route.name == 'attachments'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'attachments' }"
            >
              <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24.554"
                height="24.212"
                viewBox="0 0 24.554 24.212"
              >
                <g
                  id="Group_11393"
                  data-name="Group 11393"
                  transform="translate(-96.85 -4.02)"
                >
                  <g
                    id="Icon_feather-layers"
                    data-name="Icon feather-layers"
                    transform="translate(97.52 4.52)"
                  >
                    <path
                      id="Path_2763"
                      data-name="Path 2763"
                      d="M14.606,3,3,8.8l11.606,5.8L26.212,8.8Z"
                      transform="translate(-3 -3)"
                      fill="none"
                      :stroke="$route.name == 'attachments' ? '#fff' : '#2c2c2c'"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <path
                      id="Path_2764"
                      data-name="Path 2764"
                      d="M3,25.5l11.606,5.8,11.606-5.8"
                      transform="translate(-3 -8.091)"
                      fill="none"
                      :stroke="$route.name == 'attachments' ? '#fff' : '#2c2c2c'"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <path
                      id="Path_2765"
                      data-name="Path 2765"
                      d="M3,18l11.606,5.8L26.212,18"
                      transform="translate(-3 -6.394)"
                      fill="none"
                      :stroke="$route.name == 'attachments' ? '#fff' : '#2c2c2c'"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
              <span v-if="$route.name == 'attachments'">مرفقات الطلب</span>
            </v-tab>
            <v-tab
              replace
              v-if="
                agent.type == 'رهن' || agent.type == 'تساهيل' || agent.type == 'رهن-شراء'
              "
              :style="
                $route.name == 'prepayment'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'prepayment' }"
            >
              <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="24.554"
                height="24.212"
                viewBox="0 0 25.554 20.212"
              >
                <g
                  id="Group_11394"
                  data-name="Group 11394"
                  transform="translate(-90.85 -4.02)"
                >
                  <g
                    id="Icon_feather-credit-card"
                    data-name="Icon feather-credit-card"
                    transform="translate(89.57 -1.48)"
                  >
                    <path
                      id="Path_4082"
                      data-name="Path 4082"
                      d="M3.81,6H24.6a2.31,2.31,0,0,1,2.31,2.31V22.17a2.31,2.31,0,0,1-2.31,2.31H3.81A2.31,2.31,0,0,1,1.5,22.17V8.31A2.31,2.31,0,0,1,3.81,6Z"
                      fill="none"
                      :stroke="$route.name == 'prepayment' ? '#fff' : '#2c2c2c'"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                    <path
                      id="Path_4083"
                      data-name="Path 4083"
                      d="M1.5,15H26.91"
                      transform="translate(0 -2.07)"
                      fill="none"
                      :stroke="$route.name == 'prepayment' ? '#fff' : '#2c2c2c'"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>

              <span v-if="$route.name == 'prepayment'">الدفعة و التساهيل</span>
            </v-tab>
            <v-tab
              replace
              :style="
                $route.name == 'logs'
                  ? 'background-color: #40BE72; color: white; border-radius: 3px'
                  : ''
              "
              :to="{ name: 'logs' }"
            >
              <svg
                class="ml-2"
                id="history_2_"
                data-name="history (2)"
                xmlns="http://www.w3.org/2000/svg"
                width="22.654"
                height="23.212"
                viewBox="0 0 22.654 23.212"
              >
                <path
                  id="Path_2774"
                  data-name="Path 2774"
                  d="M29.292,16.287H16.2a.454.454,0,1,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm0,3.95H16.2a.454.454,0,0,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm-8.309,3.955H16.194a.454.454,0,0,1,0-.907h4.789a.454.454,0,0,1,0,.907Zm-1.261,3.95H16.194a.454.454,0,0,1,0-.907h3.529a.454.454,0,1,1,0,.907Zm8.767,6.381A5.946,5.946,0,1,1,32.7,32.79,5.941,5.941,0,0,1,28.489,34.524Zm0-10.976a5.039,5.039,0,1,0,3.573,1.468A5.034,5.034,0,0,0,28.489,23.548Z"
                  transform="translate(-11.799 -11.312)"
                  :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
                />
                <path
                  id="Path_2775"
                  data-name="Path 2775"
                  d="M43.3,40.448a.454.454,0,0,1-.454-.454V37.274a.454.454,0,1,1,.907,0v2.721A.454.454,0,0,1,43.3,40.448Z"
                  transform="translate(-26.613 -23.028)"
                  :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
                />
                <path
                  id="Path_2776"
                  data-name="Path 2776"
                  d="M40.243,44.4H37.7a.454.454,0,1,1,0-.907h2.54a.454.454,0,1,1,0,.907Z"
                  transform="translate(-23.553 -26.673)"
                  :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
                />
                <path
                  id="Path_2777"
                  data-name="Path 2777"
                  d="M19.5,27.617H9.318A2.268,2.268,0,0,1,7.05,25.35V8.678A2.268,2.268,0,0,1,9.318,6.41H26.67a2.268,2.268,0,0,1,2.268,2.268v8.617a.454.454,0,1,1-.907,0V8.678A1.361,1.361,0,0,0,26.67,7.317H9.318A1.361,1.361,0,0,0,7.957,8.678V25.35A1.361,1.361,0,0,0,9.318,26.71H19.5a.454.454,0,1,1,0,.907Z"
                  transform="translate(-7.05 -6.41)"
                  :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
                />
              </svg>
              <span v-if="$route.name == 'logs'">سجل الطلب</span>
            </v-tab>
            <v-tab
              replace
              :style="
                $route.name == 'tickets'
                  ? 'background-color: #40BE72; color: white; border-radius: 4px; height: 46px; margin-top: 2px;'
                  : ''
              "
              :to="{ name: 'tickets' }"
            >
              <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="23.194"
                height="23.212"
                viewBox="0 0 23.194 23.212"
              >
                <g id="ticket_menu" data-name="ticket menu" transform="translate(0)">
                  <path
                    id="Path_2758"
                    data-name="Path 2758"
                    d="M93.931,77.839a.631.631,0,0,0-.8-.076,2.521,2.521,0,0,1-3.493-3.506.638.638,0,0,0-.076-.795l-.725-.725A2.525,2.525,0,0,0,87.051,72h0a2.525,2.525,0,0,0-1.785.738L72.92,85.123a2.525,2.525,0,0,0,.006,3.563l.486.479a.636.636,0,0,0,.845.044,2.5,2.5,0,0,1,3.525,3.519.631.631,0,0,0,.038.851l.9.9a2.527,2.527,0,0,0,3.569,0h0l12.36-12.36a2.527,2.527,0,0,0,0-3.569ZM81.382,93.579a1.264,1.264,0,0,1-1.785,0l-.517-.517A3.758,3.758,0,0,0,73.929,87.9l-.107-.107a1.264,1.264,0,0,1,0-1.785l8.406-8.437,7.58,7.58Zm12.36-12.36L90.69,84.271l-7.58-7.586,3.039-3.052a1.24,1.24,0,0,1,.889-.372h0a1.26,1.26,0,0,1,.889.366l.385.385a3.788,3.788,0,0,0,5.064,5.064l.359.359a1.258,1.258,0,0,1,.006,1.785Z"
                    transform="translate(-72.187 -72)"
                    :fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'"
                  />
                  <path
                    id="Path_2759"
                    data-name="Path 2759"
                    d="M171.445,217.177l-3.058-3.058a1.264,1.264,0,0,0-1.785,0h0l-2.983,2.983a1.264,1.264,0,0,0,0,1.785l3.058,3.058a1.264,1.264,0,0,0,1.785,0l2.983-2.983a1.249,1.249,0,0,0,.032-1.772C171.47,217.177,171.463,217.171,171.445,217.177Zm-3.872,3.872-3.065-3.058,2.983-2.983h0l3.058,3.058Z"
                    transform="translate(-157.508 -204.811)"
                    :fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'"
                  />
                </g>
              </svg>
              <span v-if="$route.name == 'tickets'" style="height: 38px; margin-top: 6px"
                >تذاكر الطلب</span
              >
            </v-tab>

            <v-tab
              replace
              :style="
                $route.name == 'calls'
                  ? 'background-color: #40BE72; color: white; border-radius: 4px; height: 46px; margin-top: 2px;'
                  : ''
              "
              :to="{ name: 'calls' }"
            >
              <!-- <svg
                class="ml-2"
                xmlns="http://www.w3.org/2000/svg"
                width="23.194"
                height="23.212"
                viewBox="0 0 23.194 23.212"
              >
                <g id="ticket_menu" data-name="ticket menu" transform="translate(0)">
                  <path
                    id="Path_2758"
                    data-name="Path 2758"
                    d="M93.931,77.839a.631.631,0,0,0-.8-.076,2.521,2.521,0,0,1-3.493-3.506.638.638,0,0,0-.076-.795l-.725-.725A2.525,2.525,0,0,0,87.051,72h0a2.525,2.525,0,0,0-1.785.738L72.92,85.123a2.525,2.525,0,0,0,.006,3.563l.486.479a.636.636,0,0,0,.845.044,2.5,2.5,0,0,1,3.525,3.519.631.631,0,0,0,.038.851l.9.9a2.527,2.527,0,0,0,3.569,0h0l12.36-12.36a2.527,2.527,0,0,0,0-3.569ZM81.382,93.579a1.264,1.264,0,0,1-1.785,0l-.517-.517A3.758,3.758,0,0,0,73.929,87.9l-.107-.107a1.264,1.264,0,0,1,0-1.785l8.406-8.437,7.58,7.58Zm12.36-12.36L90.69,84.271l-7.58-7.586,3.039-3.052a1.24,1.24,0,0,1,.889-.372h0a1.26,1.26,0,0,1,.889.366l.385.385a3.788,3.788,0,0,0,5.064,5.064l.359.359a1.258,1.258,0,0,1,.006,1.785Z"
                    transform="translate(-72.187 -72)"
                    :fill="$route.name == 'calls' ? '#fff' : '#2c2c2c'"
                  />
                  <path
                    id="Path_2759"
                    data-name="Path 2759"
                    d="M171.445,217.177l-3.058-3.058a1.264,1.264,0,0,0-1.785,0h0l-2.983,2.983a1.264,1.264,0,0,0,0,1.785l3.058,3.058a1.264,1.264,0,0,0,1.785,0l2.983-2.983a1.249,1.249,0,0,0,.032-1.772C171.47,217.177,171.463,217.171,171.445,217.177Zm-3.872,3.872-3.065-3.058,2.983-2.983h0l3.058,3.058Z"
                    transform="translate(-157.508 -204.811)"
                    :fill="$route.name == 'calls' ? '#fff' : '#2c2c2c'"
                  />
                </g>
              </svg> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.194 23.212"
                width="30.194"
                height="30.212"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-2h2v2zm4 0h-2v-2h2v2zm2-4h-6V4h6v9z"
                />
              </svg>
              <span v-if="$route.name == 'calls'" style="height: 38px; margin-top: 6px"
                >المكالمات</span
              >
            </v-tab>
          </v-tabs>
          <div v-if="isAdmin">
            <v-btn
                color="primary"
                elevation="2"
                outlined
                small
                @click="show_Field_log('move_request_reason')"
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.513"
                  height="16.448"
                  viewBox="0 0 14.513 16.448"
                >
                  <path
                    id="Icon_material-subdirectory-arrow-right"
                    data-name="Icon material-subdirectory-arrow-right"
                    d="M20.513,16.643l-5.805,5.805-1.374-1.374,3.473-3.464H6V6H7.935v9.675h8.872l-3.473-3.464,1.374-1.374Z"
                    transform="translate(-6 -6)"
                  />
                </svg>
                <!-- سجل نقل الطلب -->
              </v-btn>
          </div>
          <div v-if="!isAdmin">
            <ReciveIcon  @sendRequstToAdmin="sendRequstToAdmin"/>
            <AgentSendPaymentDialog :reqId="$route.params.id" />
            <ValidationAsDialog />
            <DosentWantOurProductDialog  @sendRequstToAdmin="sendRequstToAdmin" />
          </div>
          <div v-else style="margin-bottom: 22px; margin-left: 15px">
            <AdminProcess />
          </div>
        </div>
      </v-sheet>

      <!-- for emitting event to re-execute get_request_details() function  -->
      <router-view :agent.sync="agent" />
      <!-- بيانات الطلب -->
      <v-sheet class="px-3 pb-3 mt-3 pt-3">
        <div
          class="d-flex align-center justify-center py-3"
          style="
            font-size: 18px;
            color: white;
            background-color: #451646;
            cursor: pointer;
            padding-bottom: 10px !important;
            border-radius: 4px;
          "
        >
          بيانات الطلب
        </div>
        <v-form style="background: none; color: #000" :disabled="!for_agent">
          <v-card flat class="mt-3">
            <v-layout
              row
              wrap
              style="background-color: #f3f5f7"
              class="userProfile__container"
            >
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="checkClickNumberReqType()"
                        />
                        نوع الطلب
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="['شراء', 'رهن', 'شراء-دفعة', 'رهن-شراء','رهن-مباشر']"
                        hide-details
                        v-model="agent.type"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('req_product_type')"
                        />
                        المنتج المختار
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.selected_products"
                        hide-details
                        v-model="agent.req_product_type"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>


                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('request_description_id')"
                        />
                        وصف الطلب
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.request_descriptions"
                        hide-details
                        v-model="agent.request_description_id"
                        item-text="option"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        disabled
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>


                  <!-- <v-col cols="12" sm="12">
                    <v-card class="px-3 position-relative userProfile__card">
                      <div class="switch__container">
                        <div>
                          <img
                            src="@/assets/icons/material-history.svg"
                            alt="image"
                            style="margin-left: 5px"
                            @click="show_Field_log('possible_to_sell_to_customer')"
                          />
                          هل بالامكان البيع للعميل؟
                        </div>
                        <div>
                          <v-radio-group
                            row
                            v-model="agent.possible_to_sell_to_customer"
                            :disabled="!for_agent"
                          >
                            <v-radio value="1" label="نعم"></v-radio>
                            <v-radio value="0" label="لا"></v-radio>
                          </v-radio-group>
                        </div>
                        <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />
                      </div>
                      </v-card>
                  </v-col> -->
                </v-card>
              </v-col>


              <!-- start Mortgage settings => khaled -->

              <v-col cols="12" lg="4" md="4" sm="6" xs="12" v-if="agent.type == 'رهن' ">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('customer_needs_id')"
                        />
                         احتياجات العميل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.customer_needs"
                        hide-details
                        v-model="agent.customer_needs_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('objections_challenges_id')"
                        />
                         الاعتراضات والتحديات
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.objections_challenges"
                        hide-details
                        v-model="agent.objections_challenges_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('obstacles_id')"
                        />
                          المعوقات
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.obstacles"
                        hide-details
                        v-model="agent.obstacles_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <!-- end Mortgage settings => khaled -->




              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12"  v-if="selected.includes(parseInt(CurrentRole))">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('reqSource')"
                        />
                        مصدر المعاملة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.work_sources"
                        hide-details
                        v-model="agent.source"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        :disabled="agent.source > 0"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.source == '2' && selected.includes(parseInt(CurrentRole))">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="checkClickNumberSource()"
                        />
                        اسم المتعاون
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        hide-details
                        v-model="agent.collaborator_name"
                        :disabled="agent.collaborator_id != null"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-text-field>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="checkClickNumberClassAgent()"
                        />
                        تصنيف المعامله
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.request_class"
                        hide-details
                        v-model="agent.class_id_agent"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        @click="disableLifted"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div class="switch__container">
                      <div class="mb-2">
                        <svg
                          @click="show_Field_log('possible_to_sell_to_customer')"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.76"
                          height="12.964"
                          viewBox="0 0 15.76 12.964"
                          style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                        >
                          <path
                            id="Icon_material-history"
                            data-name="Icon material-history"
                            d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                            transform="translate(-1.5 -4.5)"
                          />
                        </svg>
                        هل بالامكان البيع للعميل؟
                      </div>
                      <v-radio-group
                        row
                        v-model="agent.possible_to_sell_to_customer"
                        class="mr-n4 px-0"
                      >
                        <v-radio value="1" label="نعم"></v-radio>
                        <v-radio value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12" v-if="agent.class_id_agent == 16">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="checkClickNumberRejectionAgent()"
                        />
                        سبب الرفض
                      </div>
                      <div class="horizontal__line"></div>
                      <v-autocomplete
                        :items="$store.state.rejection_list"
                        hide-details
                        v-model="agent.rejection_id_agent"
                        item-text="title"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent"
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>
                  <div style="margin-bottom: 15px; margin-left: 10px">
                    <span
                      @click="
                        TempNote = agent.agent_notes;
                        agent.agent_notes = '';
                      "
                      style="float: left"
                      v-if="agent.agent_notes != ''"
                    >
                      clear
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <g
                          id="Icon_ionic-ios-close-circle-outline"
                          data-name="Icon ionic-ios-close-circle-outline"
                          transform="translate(-3.375 -3.375)"
                        >
                          <path
                            id="Path_5867"
                            data-name="Path 5867"
                            d="M19.089,18.109l-2.28-2.28,2.28-2.28a.691.691,0,1,0-.978-.978l-2.28,2.28-2.28-2.28a.691.691,0,0,0-.978.978l2.28,2.28-2.28,2.28a.669.669,0,0,0,0,.978.687.687,0,0,0,.978,0l2.28-2.28,2.28,2.28a.7.7,0,0,0,.978,0A.687.687,0,0,0,19.089,18.109Z"
                            transform="translate(-3.456 -3.454)"
                            fill="#2c2c2c"
                          />
                          <path
                            id="Path_5868"
                            data-name="Path 5868"
                            d="M12.375,4.587a7.785,7.785,0,1,1-5.508,2.28,7.737,7.737,0,0,1,5.508-2.28m0-1.212a9,9,0,1,0,9,9,9,9,0,0,0-9-9Z"
                            fill="#2c2c2c"
                          />
                        </g>
                      </svg>
                    </span>
                    <span
                      @click="
                        agent.agent_notes = TempNote;
                        TempNote = '';
                      "
                      style="float: left"
                      v-if="TempNote != '' && agent.agent_notes == ''"
                    >
                      undo
                      <v-icon small>mdi-undo</v-icon>
                    </span>
                  </div>
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="checkClickNumberComment()"
                        />
                        الملاحظة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-textarea
                        hide-details
                        v-model="agent.agent_notes"
                        class="mr-1"
                        outlined
                        dense
                        :disabled="!for_agent && !forMorPur"
                      >
                      </v-textarea>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-layout>
          </v-card>
        </v-form>
      </v-sheet>
      <v-sheet class="px-5 py-5 mt-3 mb-10">
        <v-form :disabled="!for_agent">
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="my-2">
              <div class="mb-2">تاريخ الطلب</div>
              <v-text-field
                type="date"
                :min="todayDateFormat"
                hide-details
                v-model="agent.follow_date"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-top: 4px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="my-2">
              <div class="mb-2">توقيت الطلب</div>
              <v-text-field
                type="time"
                hide-details
                v-model="agent.follow_time"
                outlined
                dense
                append-icon
                class="follow-req-time mr-1"
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.989"
                    height="17.989"
                    viewBox="0 0 17.989 17.989"
                    style="margin-top: 4px; cursor: pointer"
                  >
                    <g
                      id="Icon_feather-clock"
                      data-name="Icon feather-clock"
                      transform="translate(-2.5 -2.5)"
                    >
                      <path
                        id="Path_4042"
                        data-name="Path 4042"
                        d="M19.989,11.494A8.494,8.494,0,1,1,11.494,3,8.494,8.494,0,0,1,19.989,11.494Z"
                        fill="none"
                        stroke="#6c757d"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Path_4043"
                        data-name="Path 4043"
                        d="M18,9v5.1l3.4,1.7"
                        transform="translate(-6.506 -2.602)"
                        fill="none"
                        stroke="#6c757d"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
      <v-sheet
        v-if="$route.name == 'agent_data'"
        class="footer__container"
        elevation="5"
        style="
          position: sticky;
          background: #451646;
          color: white;
          bottom: 5px;
          left: 32px;
          right: 32px;
          drop-shadow: #00000014;
          opacity: 100%;
          height: 56px;
          padding: 15px;
          display: flex;
          align-items: center;
        "
      >
        <span class="my-5" style="display: inline">{{ agent.req_date }}</span>
        <v-divider class="mx-5" vertical />
        <span class="my-5" style="display: flex">
          <span style="margin-top: 7px"> تصنيف المعاملة :</span>
          <v-autocomplete
            :items="$store.state.request_class"
            hide-details
            v-model="agent.class_id_agent"
            item-text="value"
            item-value="id"
            style="border: none !important;background-color: white;"
            @click="disableLifted"
            class="mr-1"
            outlined
            dense
            :disabled="!for_agent"
          >
          </v-autocomplete>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.457"
            height="6.328"
            viewBox="0 0 11.457 6.328"
            style="margin-top: 18px; margin-right: -20px"
          >
            <path
              id="Icon_ionic-ios-arrow-down"
              data-name="Icon ionic-ios-arrow-down"
              d="M11.918,15.667l4.332-4.188a.836.836,0,0,1,1.156,0,.778.778,0,0,1,0,1.12L12.5,17.344a.838.838,0,0,1-1.129.023L6.426,12.6a.776.776,0,0,1,0-1.12.836.836,0,0,1,1.156,0Z"
              transform="translate(-6.188 -11.246)"
              fill="white"
            />
          </svg>

          <!-- تصنيف المعاملة : {{ getAgentClass || "لايوجد" }} -->
        </span>
        <v-divider class="mx-5" vertical />
      
        <span class="my-5" style="display: flex">
          <span style="margin-top: 7px"> الملاحظة :</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                hide-details
                v-model="agent.agent_notes"
                class="mr-1"
                outlined
                dense
                style="background-color: white"
              >
              </v-text-field>
            </template>
            
            <div style="background-color: white; color: black; padding: 10px; border-radius: 4px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);">
              {{ agent.agent_notes }}
            </div>
          </v-tooltip>
        </span>
        <v-divider class="mx-5" vertical />
        
        <!-- Khaled -->
        <span class="my-5" style="display: flex" v-if="agent.user">
          <span style="margin-top: 7px"> استشاري المبيعات :</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                hide-details
                v-model="agent.user.name"
                class="mr-1"
                dense
                style="background-color: white"
                disabled
              >
              </v-text-field>
            </template>

            <div style="background-color: white; color: black; padding: 10px; border-radius: 4px">
              {{ agent.user.name }}
            </div>
          </v-tooltip>
        </span>
        <v-divider class="mx-5" vertical />

        <span class="my-5" style="display: flex">
          <span style="margin-top: 7px"> حالة الطلب :</span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                hide-details
                v-model="agent.status"
                class="mr-1"
                outlined
                dense
                style="background-color: white"
                disabled
              >
              </v-text-field>
            </template>
            
            <div style="background-color: white; color: black; padding: 10px; border-radius: 4px; box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);">
              {{ agent.status }}
            </div>
          </v-tooltip>
        </span>


      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
import { axiosInstance } from "@/axios/config";
import ReciveIcon from "../Charts/ReciveIcon.vue";
import AdminProcess from "@/components/AdminComponent/AdminProcess.vue";
import AgentSendPaymentDialog from "@/components/Dialogs/AgentSendPaymentDialog.vue";
import ValidationAsDialog from "@/components/Dialogs/ValidationAsDialog.vue";
import DosentWantOurProductDialog from "@/components/Dialogs/DosentWantOurProductDialog.vue";
import css from "./DetailsStyle.css";

export default {
  name: "RequestDetails",
  data: () => ({
    TempNote: "",
    loading: false,
    openInformationRequest: false,
    active: false,
    tab: "",
    CurrentRole: 0,
    selected : [],
    agent: {},
    isAdmin: localStorage.getItem("isAdmin"),
    name: "",
    old_class_id_agent: "",
    getAgentClass: "",
    SelectedProTypes: [
      { id: "commercial_finance", value: "تمويل تجاري" },
      { id: "self_build", value: "بناء ذاتي" },
      { id: "complete_construction", value: "اكمال البناء" },
      { id: "land_loan", value: "أرض و قرض" },
      { id: "mortgage", value: "رهن عقار" },
      { id: "buy_property", value: "شراء عقار" },
      { id: "personal_loan", value: "قرض شخصي" },
    ],
    todayDateFormat:
      new Date().getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + new Date().getDate()).slice(-2),
  }),
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4
      );
    },

    forMorPur() {
      return (
        this.$store.state.request_info.statusReq == 19 &&
        this.$store.state.request_info.type == "رهن-شراء"
      );
    },

    filteredItems() {
      return this.$store.state.request_class.map((item) => {
        if (this.agent.class_id_agent === 61 && item.id === 61) {
          return {
            ...item,
            disabled: true,
          };
        } else {
          return item;
        }

        // const modifiedItem = { ...item };
        // if (this.agent.class_id_agent === 61 && item.id === 61) {
        //   modifiedItem.disabled = true;
        //   modifiedItem.class = 'special-item';
        // }
        // return modifiedItem;
      });
    },
  },
  methods: {
    toggleNavClass() {
      if (this.active == false) {
        return "nav";
      } else {
        return "sticky-nav";
      }
    },
    checkClickNumberReqType() {
      if (this.$store.state.fieldCount % 2 === 0) {
        this.show_Field_log("reqtyp1");
      } else {
        this.show_Field_log("reqtyp");
      }
      this.$store.state.fieldCount++;
    },
    checkClickNumberSource() {
      if (this.$store.state.fieldCount % 2 === 0) {
        this.show_Field_log("reqSource1");
      } else {
        this.show_Field_log("reqSource");
      }
      this.$store.state.fieldCount++;
    },
    checkClickNumberClassAgent() {
      if (this.$store.state.fieldCount % 2 === 0) {
        this.show_Field_log("class_agent1");
      } else {
        this.show_Field_log("class_agent");
      }
      this.$store.state.fieldCount++;
    },
    checkClickNumberRejectionAgent() {
      if (this.$store.state.fieldCount % 2 === 0) {
        this.show_Field_log("rejection_id_agent1");
      } else {
        this.show_Field_log("rejection_id_agent");
      }
      this.$store.state.fieldCount++;
    },
    checkClickNumberComment() {
      if (this.$store.state.fieldCount % 2 === 0) {
        this.show_Field_log("comment1");
      } else {
        this.show_Field_log("comment");
      }
      this.$store.state.fieldCount++;
    },
    disableLifted() {
      setTimeout(() => {
        document
          .querySelectorAll(
            ".v-menu__content.menuable__content__active .v-list-item__title"
          )
          .forEach((el) => {
            if (el.innerHTML == "مرفوع") {
              el.parentElement.parentElement.style.pointerEvents = "none";
              el.style.color = "#979797";
            }
          });
      }, 500);
    },

    get_request_details() {
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests/${this.$route.params.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          if (response.data.payload.type == "not_response_class") {
            if (response.data.payload && response.data.payload.request_id) {
              this.showPopupWithBtn(
                "error",
                response.data.message,
                "فتح الطلب",
                response.data.payload.request_id
              );
              this.loading = false;
              //this.$router.go(-1);
            }

            if (response.data.payload && response.data.payload.ticket_id) {
              console.log('open ticket');
              this.showPopupWithBtn(
                "error",
                response.data.message,
                "فتح التذكرة",
                response.data.payload.ticket_id
              );
              this.loading = false;
            }

          } else {
            this.agent = response.data.payload;
            this.old_class_id_agent = response.data.payload.class_id_agent;
            if (this.agent.prepayments == null) {
              this.agent.prepayments = {};
            }
            // this.$store.state.request_info = response.data.payload;
            this.$store.state.request_info = this.agent;
            this.loading = false;
            this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
            this.$store.state.request_class.forEach((el) => {
              if (el.id == this.agent.class_id_agent) {
                this.getAgentClass = el.value;
              }
            });
            this.agent.follow_time = response.data.payload.date_time_agent
              ? response.data.payload.date_time_agent.reminder_date.split(" ")[1]
              : "";
            this.agent.follow_date = response.data.payload.date_time_agent
              ? response.data.payload.date_time_agent.reminder_date.split(" ")[0]
              : "";
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log('enter in error block !');
            this.showPopup("error", err.response.data.message);
            this.loading = false;
            this.$router.go(-1);
          } else {
            console.log(err);
          }
        });
    },
    to_request_details(item) {
      this.$router.push({ name: "request_details", params: { id: item.id } });
    },
    to_tickets_page(item) {
      this.$router.push({ name: "tickets", params: { id: item.id } });
    },

    async getRequestSettings() {
      await Axios.get("https://appproduct.alwsata.com.sa/api/employee/request-source-settings", {
        headers: this.request_headers(),
      }).then((response) => {
        this.selected = response.data.payload.selected.map(value => parseInt(value));
      });
    },

    async get_rejection_list(productType) {
      let url = `https://appproduct.alwsata.com.sa/api/agent/Rejections/Reasons`;
      if(productType){
        url = `https://appproduct.alwsata.com.sa/api/agent/Rejections/Reasons?productType=${productType}`
      }
      await Axios.get(url, {
        headers: this.request_headers(),
      }).then((response) => {
        this.$store.state.rejection_list = response.data.payload;
      });
    },

    sendRequstToAdmin(values) {
      const formData = new FormData();
      if(this.agent.agent_notes == null || this.agent.agent_notes == undefined || this.agent.agent_notes == ''){
        this.showPopup("error", "الملاحظة مطلوبة");
        return;
      }
      formData.append("comment", this.agent.agent_notes);

      axiosInstance
        .post(`/v2/employee/Requests/moveToAdministration/${values}`, formData, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.showPopup("success",res.data.message)
          setTimeout(() => {
            this.$router.push({ name: "AllRequests" });
            window.location.reload();
          }, 1000);
         
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message.comment);
        });
    },

  },

  created() {
    this.get_request_details();
  },

  watch: {
    "agent.real_estate.has_property": function (newData, old) {
      if (old !== newData) {
        if (this.agent.real_estate.has_property == 0) {
          // this.agent.class_id_agent = 15;
        } else {
          // this.agent.class_id_agent = null;
        }
      }
    },

    "agent.class_id_agent": function (newData, oldVal) {
      if (oldVal !== newData) {
        if (oldVal == 15 && this.agent.real_estate.has_property == 0) {
          this.agent.real_estate.has_property = null;
        }
      }
    },

    "agent.req_product_type": function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.get_rejection_list(newVal);
      }
    },

    

    "$store.state.ValidationAsDialogMessage": {
      handler() {
        console.log("possible sell null 1")
        this.agent.possible_to_sell_to_customer = null;
      },
    },
  },

  mounted() {
    this.getRequestSettings();
    this.CurrentRole = window.localStorage.getItem("role");
    this.$store.state.openDialogMargeUsers = false;
    this.$root.$on("RD", () => {
      this.get_request_details();
    });
    window.document.onscroll = () => {
      let navBar = document.getElementById("nav");
      this.active = window.scrollY > navBar?.offsetTop;
    };
  },
  components: { AdminProcess, ReciveIcon, AgentSendPaymentDialog, ValidationAsDialog, DosentWantOurProductDialog },
};
</script>

<style lang="scss">

.footer__container .theme--light.v-input input, .theme--light.v-input textarea{
  color: black !important;
}
.v-slide-group__content .v-tabs-bar__content .a {
  text-decoration: none !important;
  color: white !important;
}

.follow-req-time {
  input {
    text-align: end !important;
  }
}

.nav {
  transition: 10ms;
  padding: 25px;
}

.sticky-nav {
  transition: 100ms;
  padding: 20px;
}

#nav {
  margin-top: 25px;
  margin-bottom: 25px;
}

#nav.sticky {
  position: fixed;
  z-index: 2;
  top: 0;
  width: 97%;
  right: 56px;
  margin-bottom: 25px;
  margin-top: 0px;
  padding-top: 20px;
  padding-left: 25px;
  padding-bottom: 10px;
}

.special-item {
  background-color: red !important;
  color: green !important;
}

.footer__container .v-select.v-text-field:not(.v-text-field--single-line) input {
  color: white;
}

// .footer__container
//   .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
//   > .v-input__control
//   > .v-input__slot
//   fieldset {
//   border: 1px solid #451646 !important;
// }

.footer__container .theme--light.v-input input,
.theme--light.v-input textarea {
  color: white;
}

.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  color: black;
}
</style>
