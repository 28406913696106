<template>
  <div class="rangePicker">
    <span style="position: absolute; z-index: 2; right: 50px; top: 32px">
      <span v-if="dateRange.startDate === null">المدة</span>
      <span v-else></span>
      <span  v-if="dateRange.startDate != null" style="position: absolute;
    top: -28px;
    right: -2rem;
    background: red;
    color: #fff;
    padding: 0px 7px;
    border-radius: 3px;
    cursor: pointer;" @click="clearDateRange"> تصفيه</span>
    </span>

    <date-range-picker
      ref="picker"
      v-model="dateRange"
      @start-selection="handleDateRangeChange($event, 'start')"
      @finish-selection="handleDateRangeChange($event, 'end')"
      
      
            :opens="opens"
            :format="format"
            :locale-data="LocalData"
            @update="updateValues"
            @toggle="logEvent('event: open', $event)"
            :ranges="DateRange"
    >
    </date-range-picker>
  </div>
</template>
<!-- 
:opens="opens"
            :locale-data="LocalData"
            :minDate="minDate" :maxDate="maxDate"
            :singleDatePicker="singleDatePicker"
            :timePicker="timePicker"
            :timePicker24Hour="timePicker24Hour"
            :showWeekNumbers="showWeekNumbers"
            :showDropdowns="showDropdowns"
            :autoApply="autoApply"
            @update="updateValues"
            @toggle="logEvent('event: open', $event)"
            :linkedCalendars="linkedCalendars"
            :dateFormat="dateFormat"
            :ranges="DateRange"
-->
<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
var moment = require("moment");
export default {
  components: { DateRangePicker },
  props:[
    'daysInputChangedTo',
  ],
  watch:{
    daysInputChangedTo(newVal){
      if (newVal != '') {
        console.log("daysInputChangedTo", newVal);
      }
    }
  },
  data() {
    return {
      opens: 'center',
      // dateFormat: 'mm/dd/yyyy', 
      DateRange:{},
      LocalData: {
          direction: 'rtl',
          format: 'dd-mm-yyyy',
          separator: ' - ',
          applyLabel: 'تاكيد',
          cancelLabel: 'الغاء',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0
      },
      direction: "ltr",
      // picker: null,
      dateRange: { startDate: null, endDate: null },
      format: "dd-mm-yyyy",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      firstDay: 0,
    };
  },

  methods: {
    logEvent(txt, ev){

    },
    updateValues(){

    },
    handleDateRangeChange(val, flag) {
      let startDate;
        let endDate;
        if (flag == 'end') {
            endDate = moment(val, 'DD-MM-YYYY', true);
            
        } else {
            startDate = moment(val, 'DD-MM-YYYY', true);
            
        }
        
      if (flag == "end" && !endDate.isValid()) {
        this.error = 'Invalid date selected. Please choose a valid date.';
        return;
      }else if ( flag != "end" && !startDate.isValid()) {
        this.error = 'Invalid date selected. Please choose a valid date.';
        return;
      }
        // 
      if (flag == "end") {
        console.log('this.$refs.picker.endText', this.$refs.picker);
        this.dateRange.endDate = moment(val).format("YYYY-MM-DD");
      } else {
        this.dateRange.startDate = moment(val).format("YYYY-MM-DD");
      }
      // const startDate = moment(val.startDate, 'DD-MM-YYYY', true);
      // const endDate = moment(val.endDate, 'DD-MM-YYYY', true);

      // if (!startDate.isValid() || !endDate.isValid()) {
      //   this.error = 'Invalid date selected. Please choose a valid date.';
      //   return;
      // }
      //   // 
      // if (flag == "end") {
      //   console.log('this.$refs.picker.endText', this.$refs.picker.endText);
      //   this.dateRange.endDate = moment(this.$refs.picker.endText).format("YYYY-MM-DD");
      // } else {
      //   this.dateRange.startDate = moment(this.$refs.picker.startText).format("YYYY-MM-DD");
      // }
      this.$emit("sendDate", this.dateRange);
    },
    clearDateRange() {
      this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      this.$emit("sendDate", this.dateRange);
    },
  },
  mounted(){
    let today = new Date()
    today.setHours(0, 0, 0, 0)

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth() , 1);
    let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    this.DateRange = {
        'اليوم': [today, today],
        'امس': [yesterday, yesterday],
        'اخر 30 يوم': [
          new Date(new Date().setDate(new Date().getDate() - 29)),
          new Date(new Date()),
        ],

        'هذا الشهر': [thisMonthStart, thisMonthEnd],
        'الشهر الماضي': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'هذا العام': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
    }
  }
};
</script>

<style>
.rangePicker .reportrange-text[data-v-1ebd09d2] {
  height: 48px;
  padding: 12px;
  border-radius: 4px;
  background: #f3f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rangePicker .vue-daterange-picker {
  width: 100%;
}

.rangePicker .daterangepicker.show-calendar .ranges {
  /* display: none; */
}

.rangePicker .daterangepicker.show-ranges[data-v-1ebd09d2] {
  /* min-width: 510px; */
}

.rangePicker .daterangepicker.openscenter[data-v-1ebd09d2] {
  left: 0px;
}
.glyphicon{
  display: none;
}
.daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked .ranges{
  text-align: right;
}
.daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked .drp-buttons{
  text-align: center;
}
.daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked,
.daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked .ranges{
  direction: rtl !important;
}
.calendars-container{
  direction: ltr;
}
</style>
