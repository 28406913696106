<template>
  <div>
    <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 20px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="بحث"
    >
      <SearchIcon />
    </v-btn>

    <v-btn
      @click="dialogOfCreating = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 80px; background-color: white"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="اضافة اعدادات لموقع الوساطه"
    >
      <img
        src="@/assets/icons/add-icon-svgrepo-com.svg"
        width="35"
        height="35"
      />
    </v-btn>

    <!-- search -->
    <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة الاعدادات"
                v-model="active_or_not"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch">
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add new mortgage settings -->

    <v-dialog v-model="dialogOfCreating" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>أضافة اعدادات لموقع الوساطة</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة الاعدادات"
                v-model="active_or_not_in_creating"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="قيمة الاعدادات"
                v-model="value_in_creating"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogOfCreating = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="addNewSetting">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit  mortgage settings -->
    <v-dialog
      v-model="$store.state.open_edit_dialog_mortgage_settings"
      persistent
      width="60%"
    >
      <v-card style="padding: 10px">
        <v-card-title>تعديل اعدادات  لموقع الوساطة</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة الاعدادات"
                v-model="edited_setting.active_as_text"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                label="قيمة الاعدادات"
                v-model="edited_setting.option"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.open_edit_dialog_mortgage_settings = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="updateSetting">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import { axiosInstance } from "@/axios/config";
export default {
  components: {
    SearchIcon,
  },
  data() {
    return {
      dialog: false,
      dialogOfCreating: false,
      active_or_not: "",
      active_or_not_in_creating: "",
      value_in_creating: "",
      edited_setting: {},
      mortgageSettingItems:[],
    };
  },

  watch: {
    "$store.state.id_of_request_description": {
      handler() {
        this.openSettingToUpdate();
      },
    },
  },

  methods: {
    handleSearch() {
      this.dialog = false;
      const body = new FormData();

      if (this.active_or_not === "نشط") {
        body.append("active_or_not", 1);
      } else if (this.active_or_not === "غير نشط") {
        body.append("active_or_not", 0);
      }

      axiosInstance
        .post(`/employee/admin/requestDescription`, body)
        .then((res) => {
          this.$emit("filterData", res.data.payload);
        });
    },

    addNewSetting() {
      const body = new FormData();
      body.append("option", this.value_in_creating);
      if (this.active_or_not_in_creating === "نشط") {
        body.append("active", 1);
      } else if (this.active_or_not_in_creating === "غير نشط") {
        body.append("active", 0);
      }


      axiosInstance
        .post(`/employee/admin/add-requestDescription`, body)
        .then((res) => {
          this.dialogOfCreating = false;
          this.$emit("settingsIsAdded");
          this.showPopup("success", res.data.message);
          this.type_of_setting_in_creating = "";
          this.active_or_not_in_creating = "";
          this.value_in_creating = "";
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    openSettingToUpdate() {
      axiosInstance
        .get(
          `/employee/admin/show-requestDescription/${this.$store.state.id_of_request_description}`,
          { headers: this.request_headers() }
        )
        .then((res) => {
          console.log(res.data.payload.data)
          this.edited_setting = res.data.payload.data;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    updateSetting() {
      const body = new FormData();
      body.append("option", this.edited_setting.option);
      if (this.edited_setting.active_as_text === "نشط") {
        body.append("active", 1);
      } else if (this.edited_setting.active_as_text === "غير نشط") {
        body.append("active", 0);
      }


      axiosInstance
        .post(`/employee/admin/update-requestDescription/${this.$store.state.id_of_request_description}`, body)
        .then((res) => {
          this.$store.state.open_edit_dialog_mortgage_settings = false;
          this.$emit("settingsIsAdded");
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
  
  },

};
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>

