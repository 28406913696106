<template>
    <div>
      <v-menu offset-y nudge-top="-20">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            height="32px"
            width="50px"
            style="min-width: unset; left: 10px"
            class="ml-5 mr-3 p-0 genaralIcons"
            color="#3f0e40"
          >
            <v-icon color="white" size="40">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            class="black--text font-weight-bold"
            style="border-bottom: 2px solid #ededed"
            @click="handleTurnuser"
            :disabled="selectedItems.length === 0 ? true : false"
          >
            <v-list-item-icon class="ml-3">
              <ConvertRequestIcon />
            </v-list-item-icon>
            <v-list-item-title>تحويل الطلب</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="selectedTab === 2 || selectedTab === 0"
            class="black--text font-weight-bold"
            style="border-bottom: 2px solid #ededed"
            @click="handleGroupOfRates"
            :disabled="selectedItems.length === 0 ? true : false"
          >
            <v-list-item-icon class="ml-3">
              <ProcessedIcon />
            </v-list-item-icon>
            <v-list-item-title>معالجة المكررة</v-list-item-title>
          </v-list-item>


          <v-list-item
            style="border-bottom: 2px solid #ededed"
            class="black--text font-weight-bold"
            :disabled="selectedItems.length === 0 ? true : false"
            @click="moveToNeedAction"
          >
            <v-list-item-icon class="ml-2">
              <AddToNeedActionIcon />
            </v-list-item-icon>
            <v-list-item-title>نقل الي سله التحويل</v-list-item-title>
          </v-list-item>

          <v-list-item
            style="border-bottom: 2px solid #ededed"
            class="black--text font-weight-bold"
            :disabled="selectedItems.length === 0 ? true : false"
            @click="convertRequest"
          >
            <v-list-item-icon class="ml-2">
              <SendToQualityIcon />
            </v-list-item-icon>
            <v-list-item-title>نقل الي الجوده</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        @click="handleFilterRateDialog"
        fab
        class="mx-2 pa-0 genaralIcons"
        small
        style="left: 80px"
        :color="$vuetify.theme.themes.light.BtnBKColor"
        depressed
        width="35"
        height="35"
      >
        <SearchIcon />
      </v-btn>

      <TurnuserDialog
        :selectedItems="selectedItems"
        :type="type"
        :isRateTransfer="true"
      />



      <template>
        <v-row justify="center">
          <v-dialog v-model="dialogQuality" persistent width="624">
            <v-card>
              <v-card-title>
                <span class="text-h5" style="margin-bottom: 30px"
                  >نقل الطلب الي الجوده</span
                >
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <SelectInput
                        @selectedValues="getSelectedValueManger"
                        :originalItems="qualityUsers"
                        label="الجوده"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="dialogQuality = false"
                >
                  الغاء
                </v-btn>
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="handleSendUserToAgent"
                >
                  تحويل
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <v-snackbar
        v-model="active"
        :color="snackColor"
        timeout="-1"
        right
        bottom
        class="ml-4"
        elevation="24"
        transition="fade-transition"
      >
        <span class="font-weight-bold" style="font-size: 16px">{{
          snackMessage
        }}</span>
        <template v-slot:action>
          <v-btn icon small @click="active = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent width="auto">
            <v-card>
              <v-card-title class="text-h5"> </v-card-title>
              <v-card-text>
                {{ messages }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="dialog = false"
                >
                  الغاء
                </v-btn>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="sureToTransfer"
                >
                  متاكد
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
  </template>

    <style>
  .genaralIcons {
    position: absolute;
    top: -50px;
    min-width: unset;
    z-index: 5;
  }
  </style>

    <script>
  import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";
  import TurnuserDialog from "@/components/AdminComponent/TurnuserDialog.vue";
  import { SearchIcon } from "@/assets/adminIcons/searchIcon";
  import Axios from "axios";
  import { qualityUser } from "@/mixins/qualityUser";
  import SelectInput from "@/components/Charts/SelectInput.vue";
/*
  import FilterRate from './FilterRate.vue';
*/
  import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
  import { AddToNeedActionIcon } from "@/assets/adminIcons/addToNeedActionIcon";
import { axiosInstance } from "@/axios/config";
import { ProcessedIcon } from "@/assets/adminIcons/processedIcon";


  export default {
    components: {
      SendToQualityIcon,
      ConvertRequestIcon,
      AddToNeedActionIcon,
      TurnuserDialog,
      SelectInput,
/*
      FilterRate,
*/
      SearchIcon,
      ProcessedIcon
    },
    props: ["selectedItems", "type",'selectedTab','selectedTabTop'],
    data() {
      return {
        qualityUsers: [],
        active: false,
        AllSelectedTable: [],
        dialog: false,
        message: "",
        snackColor: "",
        messages: "هل انت متاكد من رغبتك في ارشفت هذه الطلبات",
        allStatus: [],
        dialogQuality: false,
        selectedQuality: [],
        needToAction: [],
        snackMessage: "",
        turnuserDialog: false,
        FilterRateDialog: false,
      };
    },
    computed: {},

    methods: {
      handleFilterRateDialog() {
        this.$store.state.search_admin = true;
      },

      handleTurnuser() {
        if (this.selectedItems.length || this.$store.state.startElement.length) {
          this.$store.state.turnuserDialog = true;
        }
      },

      moveToNeedAction() {
        if (
          this.selectedItems.length ||
          this.$store.state.startElement.length > 0
        ) {
          this.messages = "هل انت متأكد من تحويل هذه الطلبات";
          this.dialog = true;
        }
      },

      async convertRequest() {
        if (
          this.selectedItems.length ||
          this.$store.state.startElement.length > 0
        ) {
          this.dialogQuality = true;

          await qualityUser().then((res) => {
            this.qualityUsers = res.data.payload.map((item) => {
              item["color"] = "red";
              item["text"] = item["name_for_admin"] || item["name"];
              return item;
            });
          });
        }
      },

      handleGroupOfRates(){
        let body = new FormData();
        this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`rate_ids[${index}]`, item["id"]);
        });

        axiosInstance.post('/employee/admin/convert-duplicate-group-to-proccess', body)
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      },

      handleSendUserToAgent() {
        this.dialogQuality = false;

        let url =
          "/v2/employee/Requests/moveToQualityReqArray";
        let body = new FormData();

        this.selectedQuality.forEach((item, index) => {
          body.append(`quality[${index}]`, item["id"]);
        });

        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["request_id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        axiosInstance.post(url, body)
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      },

      getSelectedValueManger(val) {
        this.selectedQuality = val;
      },

      sureToTransfer() {
        if (this.messages === "هل انت متأكد من تحويل هذه الطلبات") {
          this.dialog = false;
          let url =
            "/v2/employee/Requests/moveToNeedActionReqArray";
          let body = new FormData();
          this.selectedItems.length > 0 &&
            this.selectedItems.forEach((item, index) => {
              body.append(`request_ids[${index}]`, item["request_id"]);
            });

          this.$store.state.startElement.length > 0 &&
            this.$store.state.startElement.forEach((item, index) => {
              body.append(`request_ids[${index}]`, item["id"]);
            });

          axiosInstance.post(url, body)
            .then((res) => {
              this.snackMessage = res.data.message;
              this.snackColor = "green";
              this.active = true;
            })
            .catch((err) => {
              let error = { err };
              this.snackMessage = error.err.response.data.message;
              this.snackColor = "red";
              this.active = true;
            });
        }
      },

      startRateFilter(data){
        this.$emit('filterData' , data)
      }
    },
  };
  </script>
    