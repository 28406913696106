<template>
  <div class="supportContainer">
    <div v-if="!loading" style="margin-bottom: 40px">
      <OrderRequestDialog />

      <div>
        <v-card
          elevation="0"
          style="
            height: 56px;
            margin: 20px;
            border-radius: 4px !important;
            margin-top: 35px;
          "
        >
          <!-- <v-card-title class="py-2 d-flex align-center justify-center">
            {{ clientName }}
            <span v-if="mobileNumber">- {{ mobileNumber }}</span>
            <svg
              @click="to_request_details()"
              class="mr-4 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16.1"
              height="11.982"
              viewBox="0 0 16.1 11.982"
              style="
                background: #40be72;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                padding: 7px;
                cursor: pointer;
              "
            >
              <g
                id="Group_11443"
                data-name="Group 11443"
                transform="translate(-765.437 -394.636)"
              >
                <g
                  id="Icon_feather-eye"
                  data-name="Icon feather-eye"
                  transform="translate(765.937 395.136)"
                >
                  <path
                    id="Path_3993"
                    data-name="Path 3993"
                    d="M1.5,11.491S4.246,6,9.05,6s7.55,5.491,7.55,5.491-2.746,5.491-7.55,5.491S1.5,11.491,1.5,11.491Z"
                    transform="translate(-1.5 -6)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_3994"
                    data-name="Path 3994"
                    d="M17.618,15.559A2.059,2.059,0,1,1,15.559,13.5,2.059,2.059,0,0,1,17.618,15.559Z"
                    transform="translate(-8.009 -10.068)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          </v-card-title> -->

          <v-card-title class="py-2 d-flex align-center justify-center">
            {{ clientName }}
            <svg
              @click="to_request_details()"
              class="mr-4 mt-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16.1"
              height="11.982"
              viewBox="0 0 16.1 11.982"
              style="
                background: #40be72;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                padding: 7px;
                cursor: pointer;
              "
            >
              <g
                id="Group_11443"
                data-name="Group 11443"
                transform="translate(-765.437 -394.636)"
              >
                <g
                  id="Icon_feather-eye"
                  data-name="Icon feather-eye"
                  transform="translate(765.937 395.136)"
                >
                  <path
                    id="Path_3993"
                    data-name="Path 3993"
                    d="M1.5,11.491S4.246,6,9.05,6s7.55,5.491,7.55,5.491-2.746,5.491-7.55,5.491S1.5,11.491,1.5,11.491Z"
                    transform="translate(-1.5 -6)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_3994"
                    data-name="Path 3994"
                    d="M17.618,15.559A2.059,2.059,0,1,1,15.559,13.5,2.059,2.059,0,0,1,17.618,15.559Z"
                    transform="translate(-8.009 -10.068)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </g>
            </svg>
          </v-card-title>
          


          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                height="30"
                color="transparent"
                depressed
                fab
                light
                v-bind="attrs"
                v-on="on"
                style="margin-left: -10px; position: absolute; left: 15px; top: 12px"
              >
                <v-icon color="#3A5DE6" size="40">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="convertToCompleteRequest()">
                <v-list-item-icon class="ml-3">
                  <img src="@/assets/completeRequest.svg" />
                </v-list-item-icon>
                <v-list-item-title> تعين كطلب مكتمل</v-list-item-title>
              </v-list-item>
              <v-list-item @click="convertToCancleRequest()">
                <v-list-item-icon class="ml-3">
                  <img src="@/assets/cancleRequest.svg" />
                </v-list-item-icon>
                <v-list-item-title>الغاء الطلب</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
        <div v-for="item in supportDetails" :key="item.id">
          <div class="lightArrow" v-if="item.name">
            <div style="text-align: end" class="dateContainer">
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[0] }}
              </div>
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[1] }}
              </div>
            </div>
            <img src="@/assets/arrowLight.svg" />
            <div style="width: 60px; height: 2px; background: #4d204d"></div>
            <div class="problemContainer">
              <div class="customerName">{{ item.name }}</div>
              <a :href="item.images[0].image_path" target="_blank" v-if="item.images.length > 0">
                <img :src="item.images[0].image_path" alt="" width="200" height="50" />
              </a>
              <div>
                <v-row>
                  <v-col cols="12" sm="12">
                    <div>
                      <span
                        v-for="(word, index) in item.descrebtion.split(' ')"
                        :key="index"
                      >
                        <button
                          class="mobile-link"
                          v-if="word.match(/\b\d{9}\b/g)"
                          @click="showSupportSingleProcessComponent(word)"
                        >
                          <v-menu v-if="word">
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                color=""
                                small
                                depressed
                                dark
                                v-bind="attrs"
                                v-on="on"
                                @click="showSupportSingleProcessComponent(word)"
                              >
                                {{ word }}
                              </button>
                            </template>
                            <v-list>
                              <ChatSingleProcess
                                v-if="showSupportSingleProcess &&  word.includes(selectedMobile)"
                                :item="selectedMobile.match(/\b\d+\b/g)"
                              />
                            </v-list>
                          </v-menu>
                        </button>
                        <span v-else>
                          {{ word }}
                          <span v-if="index < item.descrebtion.split(' ').length - 1">
                          </span>
                        </span>
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <div class="darkArrow" v-else>
            <div class="problemContainer">
              <div class="customerName">
                {{ item.user.name }}
              </div>
              <div>{{ item.descrebtion }}</div>
            </div>
            <div style="width: 60px; height: 2px; background: #4d204d"></div>
            <img src="@/assets/arrowDark.svg" />
            <div class="dateContainer">
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[0] }}
              </div>
              <div>
                {{ item.created_at.replace(" ", " ,").split(" ,")[1] }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="showReplyTextbox">
          <div
            v-if="replayAccess && this.supportDetails[0].status !== 3"
            class="adminReplay"
          >
            <div>
              <v-textarea placeholder="اكتب رسالتك..." solo v-model="replay"></v-textarea>
              <button @click="supportReplay()" class="sendReplay">
                <img src="@/assets/sendReplay.svg" />
              </button>
            </div>
          </div>
        </div> -->

        <div v-if="showReplyTextbox">
          <div v-if="replayAccess && this.supportDetails[0].status !== 3">
            <v-container class="my-5">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <!-- <v-select
                    v-if="showMentionSuggestions"
                    label="منشن احد المختصين بالطلب"
                    class="my-0"
                    :items="mentionedUsers"
                    solo
                    v-model="mentioned_id"
                    item-text="name"
                    item-value="id"
                    outlined
                    multiple
                  ></v-select> -->
                  <div class="d-flex align-center justify-center">
                    <v-btn icon color="red" @click="openMentionDailogFromButton" style="text-align: center;" title="منشن احد المختصين بالطلب">
                      <v-icon>mdi-at</v-icon> <!-- Use mdi-at for @ icon -->
                    </v-btn>
                  </div>
                  <v-textarea
                    placeholder="اكتب رسالتك او منشن احد المختصين من خلال @"
                    solo
                    v-model="replay"
                    @input="handleMentionInput"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card elevation="0">
                <v-card-actions
                  class="d-flex align-center justify-center"
                >
                  <v-btn :disabled="isButtonDisabled"  :color="styleOfbuttonOfSendNumber" style="color:white !important" class="font-weight-bold" @click="handleReplyAction()">{{ buttonOfSend }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </div>

          <v-dialog v-model="showMentionSuggestionsDialog" width="60%">
            <v-card>
              <v-card-title>منشن احد المختصين بالطلب</v-card-title>
              <v-card-text>
                <v-select
                  v-if="showMentionSuggestions"
                  :items="mentionedUsers"
                  solo
                  v-model="mentioned_id"
                  item-text="name"
                  item-value="id"
                  outlined
                  multiple
                ></v-select>
              </v-card-text>
              <v-card-actions style="display=flex; justify-content: left;">
                <v-btn color="success" @click="mentionUserInSupport">منشن المستخدم</v-btn>
                <v-btn color="primary" @click="showMentionSuggestionsDialog = false; replay = '';">إغلاق</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
         

        </div>

        <v-card elevation="0">
          <v-card-actions
            class="d-flex align-center justify-center"
            v-if="this.supportDetails[0].status === 2"
          >
            <v-btn color="success" class="font-weight-bold">التذكرة مكتملة</v-btn>
          </v-card-actions>

          <v-card-actions
            class="d-flex align-center justify-center"
            v-if="this.supportDetails[0].status === 2"
          >
            <v-btn
              style="background-color: #4d204d; color: white"
              @click="reOpenTicket"
              class="font-weight-bold"
              >{{ changeContentOfButton() }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div v-else style="margin-top: 40px">
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style>
.sendReplay {
  position: absolute;
  top: 5px;
  left: 5px;
}

.adminReplay {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
  height: 100px;
  overflow: hidden;
}

.adminReplay > div {
  width: 904px;
  height: 100px;
  position: relative;
  background-color: white;
  border: 1px solid #eff0f6;
}

.supportContainer
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot
  ::placeholder {
  color: #6f788f;
}

.customerName {
  font-weight: bold;
  padding-bottom: 5px;
}

.dateContainer {
  width: 401px;
  padding: 15px;
  color: #666666;
  font-size: 16px;
}

.problemContainer {
  width: 341px;
  height: 128px;
  border: 1px solid #714d72;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
}

.lightArrow,
.darkArrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightArrow .problemContainer {
  background: #f5f2f5;
}

.darkArrow .problemContainer {
  background: rgb(77, 32, 77);
  border-radius: 4px;
  color: white;
}

.mobile-link {
  color: blue !important;
}
</style>

<script>
import LoadingMore from "./LoadingMore.vue";
import { axiosInstance } from "@/axios/config";
import ChatSingleProcess from "@/components/Charts/ChatSingleProcess.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";

export default {
  name: "SupporDetails",
  watch: {
    supportDetails(nValue, oValue) {
      if (this.supportDetails[0].status === 2) {
        this.showReplyTextbox = false;
      } else {
        this.showReplyTextbox = true;
      }
    },

    "$route.params.id": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getSupportDetails();
        }
      },
    },
  },

  computed: {
    getMentionedUsers() {
      return this.mentionedUsers;
    },
    styleOfbuttonOfSendNumber(){
      if(this.buttonOfSendNumber == 0){
        return 'success';
      }else{
        return '#390939';
      }
    }
  },

  data() {
    return {
      supportDetails: [],
      isCustomerHelpDesk: false,
      clientName: "",
      mobileNumber: null,
      loading: false,
      active: false,
      isButtonDisabled: false,
      message: "",
      replay: "",
      replayAccess: true,
      createdDate: "",
      eyes: false,
      totalReplay: null,
      showReplyTextbox: false,
      showSupportSingleProcess: false,
      selectedMobile: null,
      mentionedUsers : [],
      mentioned_id:[],
      showMentionSuggestions: false,
      buttonOfSend : 'ارسال',
      buttonOfSendNumber : 0,
      mobiles : [],
      showMentionSuggestionsDialog: false,
      showMentionSuggestionsDropdown: false,
      mentionSearch: '',
    };
  },
  created() {
    this.getSupportDetails("start");
  },

  methods: {
    to_request_details() {
      axiosInstance
        .get(
          `/employee/collaborator/request-of-customer-helpdesk/${this.$route.params.id}`
        )
        .then((res) => {
          // this.$router.push({
          //   name: "request_details",
          //   params: { id: res.data.payload.id },
          // });
          const routeURL = this.$router.resolve({
            name: "request_details",
            params: { id: res.data.payload.id }
          }).href;
          window.open(routeURL, '_blank');
        });
    },

    supportReplay() {
      this.isButtonDisabled = true;
      let body = new FormData();
      body.append("replay", this.replay);

      axiosInstance
        .post(
          `/employee/collaborator/replay-to-technical-support-request-from-admin/${this.$route.params.id}`,
          body
        )
        .then((res) => {
          this.replay = "";
          this.active = true;
          this.message = res.data.message;
          this.isButtonDisabled = false;
          this.getSupportDetails();
        }).catch((err) => {
          // this.active = true;
          this.isButtonDisabled = false;
          this.showPopup("error", err.response.data.errors.replay[0]);
        });
    },

    convertToCompleteRequest() {
      axiosInstance
        .get(`/employee/admin/help-desk/complete/${this.$route.params.id}`)
        .then((res) => {
          this.active = true;
          this.message = "تم تحديث حاله الطلب الي مكتمل";
          this.replayAccess = false;
        });
    },

    convertToCancleRequest() {
      axiosInstance
        .get(`/employee/admin/help-desk/cancel/${this.$route.params.id}`)
        .then((res) => {
          this.active = true;
          this.message = "تم تحديث حاله الطلب الي ملغي";
          this.replayAccess = false;
        });
    },

    getSupportDetails(load) {
      if (load === "start") {
        this.loading = true;
      }
      axiosInstance
        .get(
          `/employee/collaborator/open-technical-support-request-from-admin/${this.$route.params.id}?sort_by=created_at&sort_type=asc`
        )
        .then((res) => {
          this.supportDetails = res.data.payload.data;
          this.createdDate = res.data.payload.ticket.created_at;

          this.isCustomerHelpDesk = res.data.payload.ticket.customer_id ? true:false;
          this.clientName = res.data.payload.ticket.name;
          this.mobileNumber = res.data.payload.ticket.mobile;

          this.totalReplay = res.data.payload.total;
          this.supportDetails.map((res) => {
            if (res.has_request) {
              this.eyes = true;
            }
            // if (res.name) {
            //   this.clientName = res.name;
            //   this.mobileNumber = res.mobile;
            // }
          });
          this.getMobiles(this.supportDetails);
          this.getSupportCount();
          this.loading = false;
        });
    },

    getMobiles(details){
      // let mobiles = [];
      for (let index = 0; index < details.length; index++) {
        let words = details[index].descrebtion.split(/\s+/); // Split by any whitespace character
        let mobileNumbers = words.filter(word => /\b\d{9}\b/.test(word)); // to find mobile numbers in each word
        this.mobiles = this.mobiles.concat(mobileNumbers); // Concatenate mobile numbers to the result array like merge in PHP
      }
     
      let body = new FormData();
      body.append(`helpDeskID`, this.$route.params.id);
      
      if (this.mobiles.length > 0) {
        this.mobiles.forEach((item, index) => {
          item = item.match(/\d+/)[0];
          body.append(`mobiles[${index}]`, item);
        });
      }

      // comment to test conflict
      axiosInstance.post(`/employee/collaborator/getUsersOfRequestToMention`,body)
      .then((res) => {
        this.mentionedUsers = res.data.payload;
        this.mentionedUsers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
      
    },

    mentionUserInSupport(){
      console.log("enter mentionUserInSupport");
      this.showMentionSuggestionsDialog = false;
      let body = new FormData();

      if (this.mentioned_id.length > 0) {
        this.mentioned_id.forEach((item, index) => {
          body.append(`mentioned_id[${index}]`, item);
        });
      }

      if (this.mobiles.length > 0) {
        this.mobiles.forEach((item, index) => {
          body.append(`mobiles[${index}]`, item);
        });
      }

      axiosInstance.post(`/employee/collaborator/mentionUserInSupport/${this.$route.params.id}`,body)
        .then((res) => {
          this.getSupportDetails();
          this.active = true;
          this.message = res.data.message;
          this.mentioned_id = '';
          this.replay = '';
          this.showMentionSuggestionsDialog = false;
        })
        .catch((err) => {
          this.active = true;
          this.showPopup("error", err.response.data.message.mentioned_id[0]);
        });
    },

    handleReplyAction() {
      if (this.showMentionSuggestions) {
        this.mentionUserInSupport();
      } else {
        this.supportReplay();
      }
      this.mentioned_id = [];
      this.showMentionSuggestions = false;
    },

    handleMentionInput() {
      console.log('start to handleMentionInput')
      const lastWord = this.replay.split(' ').pop();
      if (lastWord.startsWith('@')) {
        this.showMentionSuggestionsDialog = true;
        this.showMentionSuggestions = true;
        // this.buttonOfSend = "ارسال المنشن";
        this.buttonOfSendNumber = 1;
      } else {
        this.showMentionSuggestionsDialog = false;
        // this.buttonOfSend = "ارسال";
        this.showMentionSuggestions = false;
        this.buttonOfSendNumber = 0;
      }
    },

    openMentionDailogFromButton(){
      this.showMentionSuggestionsDialog = true;
      this.showMentionSuggestions = true;
    },

    getSupportCount() {
      axiosInstance.post(`/employee/admin/help-desk`, {}).then((res) => {
        this.$store.state.newSupport = res.data.payload.count_of_new_helpdesks;
      });
    },

    reOpenTicket() {
      this.showReplyTextbox = !this.showReplyTextbox;
    },

    changeContentOfButton() {
      if (this.showReplyTextbox === true) {
        return " الغاء فتح التذكرة";
      } else {
        return "اعادة فتح التذكرة";
      }
    },

    showSupportSingleProcessComponent(number) {
      const extractedNumber = number.match(/\d+/)[0]; // This regex matches one or more digits (\d+)
      console.log(extractedNumber)
      this.showSupportSingleProcess = true;
      this.selectedMobile = extractedNumber;
    },
  },
  components: {
    LoadingMore,
    // GoBack,
    ChatSingleProcess,
    OrderRequestDialog,
    // OrderRequestSection,
  },
};
</script>
