<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.search_admin" persistent width="824">
      <v-card id="adminFilter">
        <v-card-title style="display: flex; justify-content: space-between">
          <span>البحث</span>
          <v-icon color="red" @click="$store.state.search_admin = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <hr />
        <v-card-text>
          <v-container>
            <v-row>
              <v-expansion-panels accordion class="DailyReportAdmin" v-model="panel">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="DailyReportAdmin__header"
                    style="background: #f7f7f7"
                  >
                    <span style="position: absolute">
                      <SearchIconBlack />
                    </span>
                    <div :style="requestsCount > 0 && 'color: green'">
                      <span style="margin-right: 30px; font-weight: bold">
                        تصفيه الطلبات
                      </span>
                      <span v-if="requestsCount > 0">({{ requestsCount }})</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="panalContextFilter">
                    <template>
                      <v-form>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-radio-group v-model="filterType">
                              <div style="display: flex">
                                <v-radio label="الملاحظة" value="الملاحظة"></v-radio>
                                <v-radio
                                  label="إسم العميل"
                                  value="إسم العميل"
                                  style="margin-right: 30px"
                                ></v-radio>
                              </div>
                            </v-radio-group>
                          </v-col>

                          <v-col cols="12" sm="8" md="8" style="margin-top: -15px">
                            <v-text-field
                              :label="' جزء من ' + filterType"
                              v-model="filterTypeParts"
                              outlined
                              dense
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              :items="['نعم', 'لا','لا يوجد']"
                              label=" هل بالامكان البيع للعميل؟"
                              v-model="PossibleToSellToCustomer"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <!-- <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput1"
                              @selectedValues="getSelectedValueManger"
                              :originalItems="getManagerNames"
                              label="مدير المبيعات"
                            />
                          </v-col> -->

                          <!-- <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput1"
                              @selectedValues="getFundingManagers"
                              :originalItems="getFundingManagersName"
                              label="مدير التمويل"
                            />
                          </v-col> -->
                         

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput2"
                              @selectedValues="getSelectedValueAgent"
                              :originalItems="getAgentsName"
                              label="استشاري المبيعات"
                            />
                          </v-col>
                          
                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="allStatus"
                              label="الحالة"
                              v-model="selectedStatusFilter"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="['الكل','نعم','لا']"
                              label=" الموافقه علي الارشفة ؟"
                              v-model="selectedAcceptArchivedOrNot"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <v-autocomplete
                              :items="['لا', 'نعم']"
                              label="طلبات تحتوي علي مكالمات"
                              v-model="isHasCalls"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4" md="4">
                            <SelectInput
                              ref="selectInput3"
                              @selectedValues="getSelectedProductType"
                              :originalItems="productType"
                              label="نوع المنتج"
                            />
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <SelectInput
                              ref="selectInput4"
                              @selectedValues="getRequestStatus"
                              :originalItems="requestTypeDrop"
                              label="حاله الطلب"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              :items="noteType"
                              label="حاله الملاحظه"
                              v-model="getRequestsType"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput5"
                              @selectedValues="getSourceWork"
                              :originalItems="requestSource"
                              label="مصدر المعامله"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput6"
                              @selectedValues="getTypeRequest"
                              :originalItems="typeRequest"
                              label="نوع الطلب"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <PickerInput
                              ref="pickerInput1"
                              label="تاريخ الطلب من"
                              @dateValue="saveDateFrom"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <PickerInput
                              ref="pickerInput2"
                              label="تاريخ الطلب الي"
                              @dateValue="saveDateTo"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <PickerInput
                              ref="pickerInput3"
                              label="تاريخ الأفراغ من"
                              @dateValue="saveCompleteDateFrom"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <PickerInput
                              ref="pickerInput4"
                              label="تاريخ الأفراع الي"
                              @dateValue="saveCompleteDateTo"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            lg="12"
                            style="padding-top: 0px; margin-bottom: 15px"
                          >
                            <input
                              type="checkbox"
                              :checked="updatedDate"
                              @change="handleToggle"
                            />
                            <label
                              for="vehicle1"
                              style="margin-right: 10px"
                              v-if="updatedDate"
                              >التي تم التحديث عليها</label
                            >
                            <label
                              for="vehicle1"
                              style="margin-right: 10px"
                              v-if="!updatedDate"
                              >تاريخ عدم التحديث</label
                            >
                          </v-col>

                          <v-col cols="12" sm="6" md="6" v-if="updatedDate">
                            <PickerInput label="من تاريخ" @dateValue="saveUpdatedFrom" />
                          </v-col>

                          <v-col cols="12" sm="6" md="6" v-if="updatedDate">
                            <PickerInput
                              label="الي تاريخ"
                              @dateValue="saveUpdatedTo"
                              ref="pickerInput5"
                            />
                          </v-col>

                          <v-col cols="12" sm="12" md="12" v-if="!updatedDate">
                            <PickerInput
                              label="من تاريخ"
                              @dateValue="saveUnUpdatedTo"
                              ref="pickerInput6"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput7"
                              @selectedValues="getworkList"
                              :originalItems="workList"
                              label="جهه العمل"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput8"
                              @selectedValues="getSalaryList"
                              :originalItems="salaryList"
                              label="جهه تزول الراتب"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput9"
                              @selectedValues="getFundingSide"
                              :originalItems="fundingSide"
                              label="جهه التمويل"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput7"
                              @selectedValues="getMortgageCustomerNeeds"
                              :originalItems="customerNeeds"
                              label="احتياجات العميل"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput7"
                              @selectedValues="getMortgageCustomerObjections"
                              :originalItems="CustomerObjections"
                              label="الاعتراضات والتحديات"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <SelectInput
                              ref="selectInput7"
                              @selectedValues="getMortgageObstacles"
                              :originalItems="CustomerObsatacles"
                              label="المعوقات"
                            />
                          </v-col>
                          
                        </v-row>
                      </v-form>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="DailyReportAdmin__header"
                    style="background: #f7f7f7"
                  >
                    <span style="position: absolute">
                      <SearchIconBlack />
                    </span>
                    <div :style="typesCount > 0 && 'color: green'">
                      <span style="margin-right: 30px; font-weight: bold">
                        التصنيفات
                      </span>
                      <span v-if="typesCount > 0">({{ typesCount }})</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="panalContextFilter">
                    <template>
                      <v-form style="margin-top: 20px">
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="allTypes"
                              label="جميع التصنيفات"
                              v-model="allType"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput10"
                              @selectedValues="getSelectedAgentType"
                              :originalItems="selesAgentType"
                              label="تصنيف استشاري المبيعات"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput11"
                              @selectedValues="getSelectedMangerType"
                              :originalItems="selesMangerType"
                              label="تصنيف مدير المبيعات"
                            />
                          </v-col>

                          <!-- <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput12"
                              @selectedValues="getSelectedFundingType"
                              :originalItems="fundingMangerType"
                              label="تصنيف مشرف التمويل"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput13"
                              @selectedValues="getMerteryType"
                              :originalItems="marteryMangerType"
                              label="تصنيف مدير الرهن"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput14"
                              @selectedValues="getGeneralManger"
                              :originalItems="genaralMangerType"
                              label="تصنيف المدير العام"
                            />
                          </v-col> -->
                        </v-row>
                      </v-form>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="DailyReportAdmin__header"
                    style="background: #f7f7f7"
                  >
                    <span style="position: absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.574"
                        height="16.641"
                        viewBox="0 0 18.574 16.641"
                        fill="black"
                      >
                        <g
                          id="Group_3168"
                          data-name="Group 3168"
                          transform="translate(0.5 0.5)"
                          fill="black"
                        >
                          <path
                            id="Path_30"
                            data-name="Path 30"
                            d="M14.228,591.566v-2.294a3.022,3.022,0,0,0-3.022-3.022H4.442a3.022,3.022,0,0,0-3.022,3.022v2.294"
                            transform="translate(-1.42 -575.975)"
                            fill="black"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                          <circle
                            id="Ellipse_14"
                            data-name="Ellipse 14"
                            cx="3.505"
                            cy="3.505"
                            r="3.505"
                            transform="translate(2.899 0)"
                            fill="black"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                          <path
                            id="Path_31"
                            data-name="Path 31"
                            d="M23.58,566.05a3.5,3.5,0,0,1,0,7.01"
                            transform="translate(-12.308 -566.05)"
                            fill="black"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                          <path
                            id="Path_32"
                            data-name="Path 32"
                            d="M30.47,585.3s3.23.727,2.747,5.8"
                            transform="translate(-15.693 -575.508)"
                            fill="black"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </span>
                    <div :style="clientCount > 0 && 'color: green'">
                      <span style="margin-right: 30px; font-weight: bold"> العميل </span>
                      <span v-if="clientCount > 0">({{ clientCount }})</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="panalContextFilter">
                    <template>
                      <v-form style="margin-top: 20px">
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="الهاتف"
                              v-model="phoneNumber"
                              outlined
                              v-numericOnly
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['غير سعودي', 'سعودي']"
                              label="الجنسية"
                              v-model="selectedNationality"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['شركة','مؤسسة','فرد']"
                              label="لديه"
                              v-model="selectedYouHave"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['نقاط بيع','قوائم مالية','لا يوجد']"
                              label="مصدر الدخل"
                              v-model="selectedSourceOfIncome"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['نعم','لا']"
                              label="هل لديه راتب"
                              v-model="selectedHaveSalary"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>


                          <v-col cols="12" sm="6" md="6">
                            <PickerInput
                              ref="pickerInput7"
                              label="تاريخ الميلاد"
                              @dateValue="saveBirthDate"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="راتب العميل من"
                              v-model="salaryFrom"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="راتب العميل الي"
                              v-model="salaryTo"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput15"
                              @selectedValues="getSelectedRegionValue"
                              :originalItems="selectedRegion"
                              label="منطقه العميل"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['لا', 'نعم']"
                              label="هل وجد عقار"
                              v-model="isHasProperty"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['لا', 'نعم']"
                              label="تم تحميل التطبيق"
                              v-model="isAplicationInstal"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="DailyReportAdmin__header"
                    style="background: #f7f7f7"
                  >
                    <span style="position: absolute">
                      <SearchIconBlack />
                    </span>
                    <div :style="qualityCount > 0 && 'color: green'">
                      <span style="margin-right: 30px; font-weight: bold"> الجودة </span>
                      <span v-if="qualityCount > 0">({{ qualityCount }})</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="panalContextFilter">
                    <template>
                      <v-form style="margin-top: 20px">
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <SelectInput
                              ref="selectInput16"
                              @selectedValues="getQuality"
                              :originalItems="quality"
                              label="الجوده"
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['لا', 'نعم']"
                              label="استلامه من قبل الجوده"
                              v-model="resiveFromQuality"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              :items="['لا', 'نعم']"
                              label="ارشفه من قبل الجوده"
                              v-model="qualityArchive"
                              outlined
                              dense
                            >
                              <template v-slot:item="{ item, attrs, on }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-title
                                    :id="attrs['aria-labelledby']"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput17"
                              @selectedValues="getQualityType"
                              :originalItems="qualityStatus"
                              label="حاله الطلب عند الجوده"
                            />
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <SelectInput
                              ref="selectInput18"
                              @selectedValues="getVarityQuality"
                              :originalItems="qualityUserType"
                              label="تصنيف الجوده"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          style="display=flex; justify-content: space-between;"
          class="mt-3"
        >
          <!-- <v-spacer></v-spacer> -->

          <div>
            <v-btn color="blue-darken-1" variant="text" @click="removeFilter">
              حذف الفلتر
            </v-btn>
          </div>
          <div>
            <v-btn
              color="blue-darken-1"
              variant="text"
              class="ml-2"
              @click="$store.state.search_admin = false"
            >
              الغاء
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="searchRequest">
              بحث
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Dialog from "../../../mixins/dialog";
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import { removeDuplicated } from "@/mixins/removeDublicated";
import { SourceValue } from "@/mixins/sourceValue";
import { formatDate } from "@/mixins/formatDate";
import { SearchIconBlack } from "@/assets/adminIcons/searchIconBlack";
import { axiosInstance } from "@/axios/config";

export default {
  components: { PickerInput, SelectInput, SearchIconBlack },
  mixins: [Dialog],
  props: ["pageName"],

  data() {
    return {
      body: {},
      disabled: false,
      filterType: "إسم العميل",
      panel: 0,
      filterTypeParts: "",
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري نشط"],
      allTypes: ["جميع التصنيفات", "ايجابي", "سلبي","إدارة"],
      typeRequest: [],
      allType: "",
      noteType: ["بها محتوي", "فارغه"],
      allMangerType: ["general", "gen"],
      allMangers: [],
      allAgents: [],
      allFundingManagers: [],
      selectedStatusFilter: "الكل",
      selectedAcceptArchivedOrNot: "الكل",
      selectedManger: [],
      selectedMang: [],
      selesAgentType: [],
      selesMangerType: [],
      fundingMangerType: [],
      marteryMangerType: [],
      genaralMangerType: [],
      qualityUserType: [],
      selectAgent: [],
      AgentBeforeSendingToAdministration: [],
      fundingManagers: [],
      salaryFrom: "",
      salaryTo: "",
      requestStatu: [],
      selectedRequestStatus: [],
      requestSource: [],
      selectedRegion: [],
      sourceWork: [],
      phoneNumber: "",
      selectAgentType: [],
      selectedSalesManger: [],
      selectedFundingManger: [],
      selectMortgageManger: [],
      selectGeneralManger: [],
      productType: [],
      selectedProductType: [],
      requestTypeDrop: [],
      birthDate: "",
      selectBirthData: null,
      counter: 0,
      isAplicationInstal: "",
      getRequestsType: "",
      selectedRegionList: [],
      selectedTypeRequest: [],
      requestDateFrom: "",
      requestDateTo: "",
      completeDateFrom: "",
      completeDateTo: "",
      updatedDate: false,
      updatedDateFrom: "",
      updatedDateTo: "",
      UnUpdatedDate: "",
      workList: [],
      selectedWorkList: [],

      customerNeeds: [],
      CustomerObjections: [],
      CustomerObsatacles: [],

      selectedCustomerNeeds: [],
      selectedCustomerObjections: [],
      selectedCustomerObsatacles: [],


      salaryList: [],
      selectedSalaryList: [],
      fundingSide: [],
      selectedFundingSide: [],
      quality: [],
      selectedQualityUsers: [],
      resiveFromQuality: "",
      isHasProperty: "",
      isHasCalls: "",
      qualityArchive: "",
      selectedVarityQuality: [],
      qualityStatus: [],
      selectedQualityStatus: [],

      requestsCount: 0,
      typesCount: 0,
      clientCount: 0,
      qualityCount: 0,
      selectedNationality : "",
      PossibleToSellToCustomer : "",

      selectedYouHave : "",
      selectedSourceOfIncome : "",
      selectedHaveSalary : "",

    };
  },
  computed: {
    getManagerNames() {
      return this.allMangers;
    },

    getAgentsName() {
      return this.allAgents;
    },

    getFundingManagersName() {
      return this.allFundingManagers;
    },
    
  },

  watch: {
    selectedStatusFilter(val) {
      if (val === "الكل") {
        this.getAllManger("manager");
        this.getAllAgents("agent", 1, this.selectedMang);
      } else if (val === "استشاري مؤرشف") {
        this.getAllAgents("agent", 0, this.selectedMang);
        this.getAllManger("manager ", 0);
      } else if (val === "استشاري نشط") {
        this.getAllAgents("agent", 1, this.selectedMang);
        this.getAllManger("manager ", 1);
      }
    },
    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (this.$store.state.admin_search_active && newval !== oldvalue && newval.length > 1) {
          this.searchRequest();
          
        }
      },
    },
  },

  methods: {
    removeFilter() {
      this.$store.state.admin_search_active = false;
      this.requestsCount = 0;
      this.typesCount = 0;
      this.clientCount = 0;
      this.qualityCount = 0;

      this.phoneNumber = "";
      this.filterTypeParts = "";
      this.selectedStatusFilter = "الكل";
      this.selectedAcceptArchivedOrNot = "الكل";
      this.getRequestsType = "";
      this.allType = "";
      this.salaryFrom = "";
      this.salaryTo = "";
      this.isAplicationInstal = "";
      this.resiveFromQuality = "";
      this.qualityArchive = "";
      this.selectedNationality = "";
      this.PossibleToSellToCustomer = "";
      this.selectedYouHave = "";
      this.selectedSourceOfIncome = "";
      this.selectedHaveSalary = "";
   

      for (let i = 1; i <= 18; i++) {
        if (this.$refs[`selectInput${i}`] && this.$refs[`selectInput${i}`].clear) {
          this.$refs[`selectInput${i}`].clear();
        }
      }

      for (let i = 1; i <= 7; i++) {
        if (this.$refs[`pickerInput${i}`]) {
          this.$refs[`pickerInput${i}`].date = null;
        }
      }
    },
    getQualityType(val) {
      this.selectedQualityStatus = val;
    },

    getVarityQuality(val) {
      this.selectedVarityQuality = val;
    },
    getQuality(val) {
      this.selectedQualityUsers = val;
    },

    getFundingSide(val) {
      this.selectedFundingSide = val;
    },

    getSalaryList(val) {
      this.selectedSalaryList = val;
    },

    getworkList(val) {
      this.selectedWorkList = val;
    },

    getMortgageCustomerNeeds(val) {
      this.selectedCustomerNeeds = val;
    },

    getMortgageObstacles(val) {
      this.selectedCustomerObsatacles = val;
    },

    getMortgageCustomerObjections(val) {
      this.selectedCustomerObjections = val;
    },

    saveUpdatedFrom(val) {
      this.updatedDateFrom = val;
    },

    saveUpdatedTo(val) {
      this.updatedDateTo = val;
    },

    saveUnUpdatedTo(val) {
      this.UnUpdatedDate = val;
    },

    handleToggle() {
      this.updatedDate = !this.updatedDate;
    },

    saveBirthDate(val) {
      this.birthDate = val;
    },

    saveDateFrom(val) {
      this.requestDateFrom = val;
    },

    saveDateTo(val) {
      this.requestDateTo = val;
    },

    saveCompleteDateFrom(val) {
      this.completeDateFrom = val;
    },

    saveCompleteDateTo(val) {
      this.completeDateTo = val;
    },

    requestStatus() {
      axiosInstance.get("/agent/RequestStatus").then((response) => {
        this.requestTypeDrop = response.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    get_work_list() {
      this.workList = this.$store.state.works_list.map((item) => {
        item["color"] = "red";
        return item;
      });
    },

    MortgageCustomerNeeds() {
      axiosInstance.get("/employee/admin/mortgage-settings-CustomerNeeds").then((res) => {
        this.customerNeeds = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });

    },

    MortgageCustomerObjections() {
      axiosInstance.get("/employee/admin/mortgage-settings-CustomerObjections").then((res) => {
        this.CustomerObjections = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    MortgageCustomerObsatacles() {
      axiosInstance.get("/employee/admin/mortgage-settings-CustomerObsatacles").then((res) => {
        this.CustomerObsatacles = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

   
    getRequestSource() {
      axiosInstance.get("/agent/Request/Sources").then((res) => {
        this.requestSource = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getSelectedRegion() {
      axiosInstance.get("/agent/regions").then((res) => {
        this.selectedRegion = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["region_ip"];
          return item;
        });
      });
    },

    get_salary_sources() {
      axiosInstance.get("/customer/SalarySources").then((res) => {
        this.salaryList = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getSelectedRegionValue(val) {
      this.selectedRegionList = val;
    },

    getRequestType() {
      this.requestTypeDrop = this.$store.state.request_statuses.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getSourceWork(val) {
      this.sourceWork = val;
    },

    searchRequest() {
      this.$store.state.isSearch = true;
      // this.$store.state.showCards = true;
      this.body = new FormData();
      this.$store.state.search_admin = false;

      this.$store.state.allRequests = [];
      this.$store.state.allSearch = [];
      this.$store.state.allData = [];
      this.$store.state.recievedSearch = [];
      this.$store.state.recievedData = [];
      this.$store.state.followSearch = [];
      this.$store.state.followData = [];
      this.$store.state.featureSearch = [];
      this.$store.state.featureData = [];
      this.$store.state.archiveSearch = [];
      this.$store.state.archiveData = [];
      this.$store.state.latestData = [];

      this.$store.state.tableData = [];
      this.$store.state.InfinityPageNumber = 1;

      this.$store.state.loadingData = true;
      this.$store.state.allLoading = true;
      this.$store.state.recievedLoading = true;
      this.$store.state.followLoading = true;
      this.$store.state.featureLoading = true;
      this.$store.state.archiveLoading = true;
      this.$store.state.latestLoading = true;

      this.$store.state.back = true;
      this.$store.state.startElement = [];
      this.$store.state.bodySearch = {};
      this.counter++;

      this.requestsCount = 0;
      this.typesCount = 0;
      this.clientCount = 0;
      this.qualityCount = 0;

      if (this.selectedMang.length > 0) {
        this.selectedMang.forEach((item, index) => {
          this.body.append(`sales_manager_ids[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectAgent.length > 0) {
        this.selectAgent.forEach((item, index) => {
          this.body.append(`agent_name[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      

      if (this.AgentBeforeSendingToAdministration.length > 0) {
        this.AgentBeforeSendingToAdministration.forEach((item, index) => {
          this.body.append(`AgentBeforeSendingToAdministration[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.fundingManagers.length > 0) {
        this.fundingManagers.forEach((item, index) => {
          this.body.append(`FundingManager[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      

      if (this.selectedRequestStatus.length > 0) {
        this.selectedRequestStatus.forEach((item, index) => {
          this.body.append(`request_status[${index}]`, item["status_id"]);
        });
        this.requestsCount++;
      }

      if (this.sourceWork.length > 0) {
        this.sourceWork.forEach((item, index) => {
          // this.body.append(`work_source[${index}]`, item["id"]);
          this.body.append(`request_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectAgentType.length > 0) {
        this.selectAgentType.forEach((item, index) => {
          this.body.append(`agent_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedSalesManger.length > 0) {
        this.selectedSalesManger.forEach((item, index) => {
          this.body.append(`sales_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedSalaryList.length > 0) {
        this.selectedSalaryList.forEach((item, index) => {
          this.body.append(`salary_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedFundingManger.length > 0) {
        this.selectedFundingManger.forEach((item, index) => {
          this.body.append(`funding_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectMortgageManger.length > 0) {
        this.selectMortgageManger.forEach((item, index) => {
          this.body.append(`mortgage_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectGeneralManger.length > 0) {
        this.selectGeneralManger.forEach((item, index) => {
          this.body.append(`general_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedProductType.length > 0) {
        this.selectedProductType.forEach((item, index) => {
          this.body.append(`request_product_type[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedRegionList.length > 0) {
        this.selectedRegionList.forEach((item, index) => {
          this.body.append(`customer_region_ip[${index}]`, item["text"]);
        });
        this.clientCount++;
      }

      if (this.selectedTypeRequest.length > 0) {
        this.selectedTypeRequest.forEach((item, index) => {
          this.body.append(`request_types[${index}]`, item["value"]);
        });
        this.requestsCount++;
      }

      if (this.selectedWorkList.length > 0) {
        this.selectedWorkList.forEach((item, index) => {
          this.body.append(`work_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedCustomerNeeds.length > 0) {
        this.selectedCustomerNeeds.forEach((item, index) => {
          this.body.append(`MortgageCustomerNeeds[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedCustomerObjections.length > 0) {
        this.selectedCustomerObjections.forEach((item, index) => {
          this.body.append(`MortgageCustomerObjections[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedCustomerObsatacles.length > 0) {
        this.selectedCustomerObsatacles.forEach((item, index) => {
          this.body.append(`MortgageCustomerObsatacles[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }


      if (this.selectedFundingSide.length > 0) {
        this.selectedFundingSide.forEach((item, index) => {
          this.body.append(`funding_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedQualityUsers.length > 0) {
        this.selectedQualityUsers.forEach((item, index) => {
          this.body.append(`quality_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.selectedVarityQuality.length > 0) {
        this.selectedVarityQuality.forEach((item, index) => {
          this.body.append(`quality_classification_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.selectedQualityStatus.length > 0) {
        this.selectedQualityStatus.forEach((item, index) => {
          this.body.append(`quality_status_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.allType === "سلبي") {
        this.body.append("agent_positive_or_negative_classification", 0);
        this.typesCount++;
      }

      if (this.allType === "ايجابي") {
        this.body.append("agent_positive_or_negative_classification", 1);
        this.typesCount++;
      }

      if (this.allType === "إدارة") {
        this.body.append("agent_positive_or_negative_classification", 2);
        this.typesCount++;
      }

      if (this.selectedStatusFilter === "استشاري نشط") {
        this.body.append("agent_active_or_archived_classification", 1);
        this.requestsCount++;
      }

      if (this.selectedStatusFilter === "استشاري مؤرشف") {
        this.body.append("agent_active_or_archived_classification", 0);
        this.requestsCount++;
      }

      
      if (this.selectedAcceptArchivedOrNot === "نعم") {
        this.body.append("ApproveForQualityArchivedOrNot", 1);
        this.requestsCount++;
      }

      if (this.selectedAcceptArchivedOrNot === "لا") {
        this.body.append("ApproveForQualityArchivedOrNot", 0);
        this.requestsCount++;
      }

      if (this.phoneNumber) {
        this.body.append("customer_phone", this.phoneNumber);
        this.clientCount++;
      }

      if (this.salaryFrom) {
        this.body.append("customer_salary_from", this.salaryFrom);
        this.clientCount++;
      }

      if (this.salaryTo) {
        this.body.append("customer_salary_to", this.salaryTo);
        this.clientCount++;
      }

      if (this.birthDate) {
        this.body.append("customer_birth_date", this.birthDate);
        this.clientCount++;
      } else {
        this.body.delete("customer_birth_date");
      }

      if (this.counter === 1) {
        this.$store.state.allRequests = [];
        this.body.append("page", 1);
      }

      if (this.getRequestsType === "فارغه") {
        this.body.append("note_status", 0);
        this.requestsCount++;
      }

      if (this.getRequestsType === "بها محتوي") {
        this.body.append("note_status", 1);
        this.requestsCount++;
      }

      if (this.isAplicationInstal === "نعم") {
        this.body.append("customer_app_download", 1);
        this.clientCount++;
      }

      if (this.isAplicationInstal === "لا") {
        this.body.append("customer_app_download", 0);
        this.clientCount++;
      }

      if (this.selectedNationality === "سعودي") {
        this.body.append("CustomerNationality", 1);
        this.clientCount++;
      }

      if (this.selectedNationality === "غير سعودي") {
        this.body.append("CustomerNationality", 5);
        this.clientCount++;
      }
      
      if (this.PossibleToSellToCustomer === "نعم") {
        this.body.append("RequestPossibleToSellToCustomer", 1);
        this.clientCount++;
      }

      if (this.PossibleToSellToCustomer === "لا") {
        this.body.append("RequestPossibleToSellToCustomer", 0);
        this.clientCount++;
      }

      if (this.PossibleToSellToCustomer === "لا يوجد") {
        this.body.append("RequestPossibleToSellToCustomer", 2);
        this.clientCount++;
      }

      if (this.selectedYouHave === "شركة") {
        this.body.append("CustomerYouHave", "company");
        this.clientCount++;
      }

      if (this.selectedYouHave === "مؤسسة") {
        this.body.append("CustomerYouHave", "institution");
        this.clientCount++;
      }

      if (this.selectedYouHave === "فرد") {
        this.body.append("CustomerYouHave", "individual");
        this.clientCount++;
      }

      if (this.selectedSourceOfIncome === "نقاط بيع") {
        this.body.append("CustomerSourceOfIncome", "selling_points");
        this.clientCount++;
      }

      if (this.selectedSourceOfIncome === "قوائم مالية") {
        this.body.append("CustomerSourceOfIncome", "financial_menu");
        this.clientCount++;
      }

      if (this.selectedSourceOfIncome === "لا يوجد") {
        this.body.append("CustomerSourceOfIncome", "nothing");
        this.clientCount++;
      }

      if (this.selectedHaveSalary === "نعم") {
        this.body.append("CustomerHaveSalary", "yes");
        this.clientCount++;
      }

      if (this.selectedHaveSalary === "لا") {
        this.body.append("CustomerHaveSalary", "no");
        this.clientCount++;
      }



      if (this.requestDateFrom) {
        this.body.append("request_date_from", this.requestDateFrom);
        this.requestsCount++;
      }

      if (this.requestDateTo) {
        this.body.append("request_date_to", this.requestDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_to");
      }

      if (this.completeDateFrom) {
        this.body.append("complete_date[0]", this.completeDateFrom);
        this.requestsCount++;
      } else {
        this.body.delete("complete_date[0]");
      }

      if (this.completeDateTo) {
        this.body.append("complete_date[1]", this.completeDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("complete_date[1]");
      }

      if (this.updatedDateFrom) {
        this.body.append("request_date_updated_from", this.updatedDateFrom);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_updated_from");
      }

      if (this.updatedDateTo) {
        this.body.append("request_date_updated_to", this.updatedDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_updated_to");
      }

      if (this.UnUpdatedDate) {
        this.body.append("request_date_did_not_updated_from", this.UnUpdatedDate);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_did_not_updated_from");
      }

      if (this.resiveFromQuality === "نعم") {
        this.body.append("quality_request_received", 1);
        this.qualityCount++;
      }

      if (this.resiveFromQuality === "لا") {
        this.body.append("quality_request_received", 0);
        this.qualityCount++;
      }

      if (this.qualityArchive === "لا") {
        this.body.append("request_archived_by_quality", 0);
        this.qualityCount++;
      }

      if (this.isHasProperty === "نعم") {
        this.body.append("real_estate_has_property", 1);
      }

      if (this.isHasProperty === "لا") {
        this.body.append("real_estate_has_property", 0);
      }

      if (this.isHasCalls === "نعم") {
        this.body.append("request_has_calls", 1);
      }

      if (this.isHasCalls === "لا") {
        this.body.append("request_has_calls", 0);
      }

      if (this.qualityArchive === "نعم") {
        this.body.append("request_archived_by_quality", 1);
        this.qualityCount++;
      }

      if (this.filterTypeParts) {
        this.body.append("text_name", this.filterTypeParts);
        this.requestsCount++;
      }

      let url = `/v2/employee/Requests/search?type=${this.pageName}&per_page=${this.$store.state.counterOfShow}`;
      axiosInstance
        .post(url, this.body)
        .then((res) => {
          
          if(this.pageName == 'funding_duration_average'){
            this.$emit('refresh_funding_duration_average',res.data.payload.sum_of_funding_duration)
          }
          this.$store.state.admin_search_active = true;
          let response = res.data.payload.data;
          this.$store.state.allRequests.push(...response);

          this.$store.state.allData.push(...response);
          this.$store.state.allSearch.push(...response);

          this.$store.state.recievedData.push(...response);
          this.$store.state.recievedSearch.push(...response);

          this.$store.state.followData.push(...response);
          this.$store.state.followSearch.push(...response);

          this.$store.state.featureData.push(...response);
          this.$store.state.featureSearch.push(...response);

          this.$store.state.archiveData.push(...response);
          this.$store.state.archiveSearch.push(...response);

          this.$store.state.latestData.push(...response);
          this.$store.state.latestSearch.push(...response);

          this.$store.state.loadingData = false;
          res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.loadingData = false;
          this.$store.state.allLoading = false;
          this.$store.state.recievedLoading = false;
          this.$store.state.followLoading = false;
          this.$store.state.featureLoading = false;
          this.$store.state.archiveLoading = false;
          this.$store.state.latestLoading = false;
          this.$store.state.pagination.count = res.data.payload.searched;
        })
        .catch((err) => {
          this.$store.state.loadingData = false;
          this.$store.state.allLoading = false;
          this.$store.state.recievedLoading = false;
          this.$store.state.followLoading = false;
          this.$store.state.featureLoading = false;
          this.$store.state.archiveLoading = false;
          this.$store.state.latestLoading = false;
        });
      this.$store.state.bodySearch = this.body;
    },

    setTblData(res) {
      const newItem = {
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        created_at: formatDate(res.created_at),
        type: res.type || "لا يوجد نوع طلب",

        "تاريخ الدخول": res.recived_date_report,
        "تاريخ الخروج": res.exit_date_from_funding_report,
        "المدة بالايام": res.funding_duration_average,
        "مدير التمويل": res.fundingManager?.name,

        "استشاري المبيعات": res.user?.name || res.userBeforeSendingToAdministration?.name,
        "تمت المعالجة": res.is_administration_processed == 1 ? 'نعم': 'لا', 
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        status: res.status,
        "مصدر المعامله": SourceValue(res.source),
        source: SourceValue(res.source),
        "تصنيف الاستشاري": res.class_id_agent_text,
        agent_notes: res.agent_notes,
        quality_notes: res.quality_notes,
        "تاريخ الاستشاري": formatDate(res.agent_date),
        "هل تم استلامه من قبل الجوده":
          (res.class_id_quality != null || res.quacomment != null) ? "نعم" :  "لا",
          // res.user?.allow_recived && res.user?.allow_recived === 0 ? "لا" : "نعم",
        "تم التحديث في": formatDate(res.updated_at),
        العمليات: res.sent_basket?.count,
      };

      const exists = this.$store.state.tableData.some((item) => item.id === newItem.id);

      if (!exists) {
        this.$store.state.tableData.push(newItem);
      }
    },

    requestClass(data) {
      data.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getTypeRequestList() {
      let data = [
        {
          color: "red",
          value: "شراء",
        },
        {
          color: "red",
          value: "رهن",
        },
        {
          color: "red",
          value: "تساهيل",
        },
        {
          color: "red",
          value: "شراء-دفعة",
        },
        {
          color: "red",
          value: "رهن-شراء",
        },
      ];
      this.typeRequest = data.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getRequestClassification(val) {
      let url = `/agent/Request/Classification?type_of_classification=${val}`;
      axiosInstance.get(url).then((res) => {
        if (val === 1) {
          this.selesMangerType = res.data.payload;
          this.requestClass(this.selesMangerType);
        } else if (val === 2) {
          this.fundingMangerType = res.data.payload;
          this.requestClass(this.fundingMangerType);
        } else if (val === 3) {
          this.marteryMangerType = res.data.payload;
          this.requestClass(this.marteryMangerType);
        } else if (val === 4) {
          this.genaralMangerType = res.data.payload;
          this.requestClass(this.genaralMangerType);
        } else if (val === 5) {
          this.qualityUserType = res.data.payload;
          this.requestClass(this.qualityUserType);
        } else if (val === 0) {
          this.selesAgentType = res.data.payload;
          this.requestClass(this.selesAgentType);
        }
      });
    },

    getSelectedValueManger(val) {
      this.selectedMang = val;
      if (val.length && this.selectedStatusFilter === "استشاري مؤرشف") {
        this.getAllAgents("agent", 0, val);
      } else if (val.length && this.selectedStatusFilter === "استشاري نشط") {
        this.getAllAgents("agent", 1, val);
      } else if (val.length && this.selectedStatusFilter === "الكل") {
        this.getAllAgents("agent", 1, val);
      }
    },

    getTypeRequest(val) {
      this.selectedTypeRequest = val;
    },

    getSelectedValueAgent(val) {
      this.selectAgent = val;
    },

    

    getAgentBeforeSendingToAdministration(val) {
      this.AgentBeforeSendingToAdministration = val;
    },

    getFundingManagers(val) {
      this.fundingManagers = val;
    },

    getSelectedAgentType(val) {
      this.selectAgentType = val;
    },

    getRequestStatus(val) {
      this.selectedRequestStatus = val;
    },

    getSelectedMangerType(val) {
      this.selectedSalesManger = val;
    },

    getSelectedFundingType(val) {
      this.selectedFundingManger = val;
    },

    getMerteryType(val) {
      this.selectMortgageManger = val;
    },

    getGeneralManger(val) {
      this.selectGeneralManger = val;
    },

    getSelectedProductType(val) {
      this.selectedProductType = val;
    },

    getAllManger(role, status) {
      this.allMangers = [];
      let body = { role: role };
      if (status === 0) {
        body = {
          status: status,
          role: role,
        };
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allMangers = res.data.payload;
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getAllFundingManagers(role, status) {
      this.allFundingManagers = [];
      let body = { role: role };
      if (status === 0) {
        body = {
          status: status,
          role: role,
        };
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allFundingManagers = res.data.payload;
        this.allFundingManagers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getQualityStatus() {
      let url = "/employee/quality/quality-request/request/status";
      axiosInstance.get(url).then((res) => {
        this.qualityStatus = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["status_value"];
          return item;
        });
      });
    },

    getAllAgents(role, status, val) {
      this.allAgents = [];
      let body = new FormData();
      body.append("role", role);
      if (status === 0 && !val) {
        body.append("status", status);
        body.append("role", role);
      } else if (status === 1 && !val) {
        body.append("status", status);
        body.append("role", role);
      }
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger);

      if (removeDublicate.length) {
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append("status", status);
        body.append("role", role);
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allAgents = res.data.payload;
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    get_customer() {
      if (document.cookie !== "") {
        axiosInstance.get("/agent/RelatedCustomers").then((response) => {
          if (this.$store.state.agent_customers) {
            this.$store.state.agent_customers = response.data.payload;
          }
        });
      }
    },

    getSupAdmin() {
      let url = "/v2/employee/quality-users";
      axiosInstance.get(url).then((res) => {
        this.quality = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name_for_admin"];
          return item;
        });
      });
    },

    get_funding_sources() {
      axiosInstance.get("/agent/Funding/Sources").then((response) => {
        this.fundingSide = response.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    get_collaborators() {
      this.getting = true;
      axiosInstance
        .get("/agent/Collaborators")
        .then((response) => {
          this.collaborators = response.data.payload.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getting = false;
        });
    },

    getProductType() {
      let url = "/customer/SelectedProduct";
      axiosInstance.get(url).then((res) => {
        this.productType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },
  },

  mounted() {
    this.getAllFundingManagers("fundingManager");
    this.getAllManger("manager");
    this.getAllAgents("agent");
    this.getRequestClassification(0);
    this.getRequestClassification(1);
    this.getRequestClassification(2);
    this.getRequestClassification(3);
    this.getRequestClassification(4);
    this.getRequestClassification(5);
    this.getProductType();
    this.requestStatus();
    this.getRequestSource();
    this.getRequestType();
    this.getSelectedRegion();
    this.getTypeRequestList();
    this.get_work_list();
    this.MortgageCustomerNeeds();
    this.MortgageCustomerObjections();
    this.MortgageCustomerObsatacles();
    this.get_salary_sources();
    this.get_funding_sources();
    this.getSupAdmin();
    this.getQualityStatus();
  },
};
</script>

<style>
#adminFilter
  .selectInput
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 2px solid #f7f8fb !important;
  margin-top: 0 !important;
}

#adminFilter .v-text-field > .v-input__control > .v-input__slot {
}

.v-chip--select {
  background-color: #3c5de7 !important;
  color: white !important;
  border-radius: 1% !important;
}

.v-chip__close::before {
  color: white !important;
}

.v-col {
  margin: 0px !important;
}

::-webkit-calendar-picker-indicator {
  left: -22px;
  position: absolute;
  z-index: 1;
  opacity: 0;
}

input[type="date"] {
  text-align: end;
}

.panalContextFilter .col-md-4,
.panalContextFilter .col-md-6,
.panalContextFilter .col-lg-12,
.panalContextFilter .col-md-12 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: black !important;
  background: #f7f7f7;
}
</style>
