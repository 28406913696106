<template>
  <page-component style="position: relative" :loading="loading" class="log-page">
    <template #content>
      <div class="supportContainer">
        <div style="margin-bottom: 40px">
          <div>
            <div v-for="(item, index) in request_logs" :key="item.id">
              <div class="lightArrow" v-if="index % 2 === 0">
                <div
                  :style="`border-color:${returnColor(item)} `"
                  style="text-align: end; width: 371px"
                  class="dateContainer"
                ></div>
                <div
                  style="
                    height: 79px;
                    border: 1px dashed #e1d9e1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    :style="`background:${returnColor(item)}`"
                    style="
                      width: 40px;
                      height: 40px;
                      position: absolute;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding-top: 4px;
                    "
                  >
                    <OrderIcons :item="item" />
                  </div>
                </div>

                <div style="width: 42px; display: flex; justify-content: end">
                  <div
                    class="side__arrow"
                    :style="`border-left:8px solid ${returnColor(item)}; margin-lef: 4px`"
                  ></div>
                </div>

                <div class="leftBox" :style="`border-color:${returnColor(item)} `">
                  <div style="display: flex; justify-content: space-between">
                    <div class="customerName" style="font-size: 14px; color: #3f0e40">
                      {{ item.status }}
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;
                        font-weight: 600;
                        color: #a4a3a3;
                      "
                    >
                      {{ item.raw_date.split("T")[0] }} -
                      {{ item.raw_date.split("T")[1].split(".")[0] }}
                    </div>
                  </div>

                  <v-tooltip bottom color="#451646">
                    <template v-slot:activator="{ on }">
                      <v-card-text
                        v-on="on"
                        class="pb-0 pt-0 px-0"
                        style="font-size: 14px"
                      >
                        {{
                          item.value
                            ? item.value.length > 50
                              ? item.value.substring(0, 50) + "..."
                              : item.value
                            : ""
                        }}
                      </v-card-text>
                    </template>
                    <div style="font-size: 14px">
                      {{ item.value }}
                    </div>
                  </v-tooltip>
                </div>
              </div>

              <div class="darkArrow" v-else>
                <div class="leftBox" :style="`border-color:${returnColor(item)} `">
                  <div class="customerName">
                    <div style="display: flex; justify-content: space-between">
                      <div class="customerName" style="font-size: 14px; color: #3f0e40">
                        {{ item.status }}
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          font-size: 12px;
                          font-weight: 600;
                          color: #a4a3a3;
                        "
                      >
                        {{ item.raw_date.split("T")[0] }} -
                        {{ item.raw_date.split("T")[1].split(".")[0] }}
                      </div>
                    </div>
                  </div>
                  <v-tooltip bottom color="#451646">
                    <template v-slot:activator="{ on }">
                      <v-card-text
                        v-on="on"
                        class="pb-0 pt-0 px-0"
                        style="font-size: 14px; margin-top: -4px"
                      >
                        {{
                          item.value
                            ? item.value.length > 50
                              ? item.value.substring(0, 50) + "..."
                              : item.value
                            : ""
                        }}
                      </v-card-text>
                    </template>
                    <div style="font-size: 14px">
                      {{ item.value }}
                    </div>
                  </v-tooltip>
                </div>
                <div style="width: 42px; display: flex; justify-content: start">
                  <div
                    class="side__arrow"
                    style="margin-right: -4px"
                    :style="`border-right:8px solid ${returnColor(item)}`"
                  ></div>
                </div>
                <div
                  style="
                    height: 79px;
                    border: 1px dashed #e1d9e1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <div
                    :style="`background:${returnColor(item)}`"
                    style="
                      width: 40px;
                      height: 40px;
                      position: absolute;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding-top: 4px;
                    "
                  >
                    <OrderIcons :item="item" />
                  </div>
                </div>
                <div class="dateContainer" style="width: 371px"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <v-sheet class="px-5" style="margin-top: 10px; padding-top: 15px">
        <div class="font-weight-bold"
        >سجل الطلب</div
        >
        <v-timeline class="my-10" align-top dense style="margin-right: 45px;">
          <v-timeline-item
              color="primary"
              small
              v-for="(item, index) in request_logs"
              :key="item.id"
          >
            <template v-slot:icon>
              <div class="logDate">
                <div>
                  {{ item.raw_date.split("T")[0] }}
                </div>

                <div>{{
                    item.raw_date.split("T")[1].split(".")[0]
                  }}
                </div>
              </div>
            </template>
            <v-alert
                border="left"
                colored-border
                :color="getAlertColor(index)"
                elevation="2"
                style="
                    border: 1px solid #c5c5c5;
                    box-shadow: unset !important;
                    background-color: #fbfbfb;
                    padding: 5px 15px !important;
                    font-size: 14px;
                    margin-top: 2px;
                    font-weight: bold;
                    border-radius: 6px;
                  "
                class="ml-5"
            >
              <div style="
              position: absolute;
              right: -15px;
              top: 15px;">
                <img src="@/assets/icons/Polygon.svg" alt="image"/>
              </div>
              <div class="pr-4 info-dad">
                <span style="font-size: 18px; font-weight: bold">{{ item.title }}</span>
              </div>
              <div class="my-2">
                <span>{{ item.text }} : {{ item.value }}</span>
              </div>
            </v-alert>
          </v-timeline-item>
        </v-timeline>


      </v-sheet> -->
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
import OrderIcons from "../RequestDetailsForDefaultVue/OrderIcons.vue";
export default {
  props: ["agent"],
  components: { OrderIcons },
  data() {
    return {
      request_logs: [],
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
      loading: false,
    };
  },
  created() {
    this.get_request_logs();
  },
  methods: {
    returnColor(item) {
      if (
        item.english_status == "sended_request" ||
        item.english_status == "created_request" ||
        item.english_status == "completed_request"
      ) {
        return "#25D366";
      } else if (
        item.english_status == "rejected_request" ||
        item.english_status == "moved_request"
      ) {
        return "#EE0000";
      } else if (item.english_status == "agent_notes") {
        return "#E5B5E5;";
      } else if (item.english_status == "sarvice_rating") {
        return "#FF9A6C";
      } else if (item.english_status == "pulled_request") {
        return "#E00";
      } else if (item.english_status == "duplicated_request") {
        return "#047D87";
      } else if (item.english_status == "ask_for_real_estate") {
        return "#DBB622";
      } else {
        return "#3F0E40";
      }
    },
    getAlertColor(index) {
      return index % 2 === 0 ? "#90DBAE" : "#e6b5e6";
    },

    get_request_logs() {
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/agent/Request/History-With-Notes/${this.$store.state.request_info.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          this.request_logs = response.data.payload;
          this.loading = false;
          this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.loading = false;
        });
    },
  },
  watch: {
    agent_data: {
      handler(val) {
        val.customer.birth_date = this.date.birth_date;
        val.customer.birth_date_higri = this.date.birth_date_higri;
        val.source = parseInt(val.source);
        val.customer.work = parseInt(val.customer.work);
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
};
</script>
<style lang="scss">
.log-page {
  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    background-color: #4516466c !important;
    width: 3px !important;
  }
  .theme--light.v-timeline .v-timeline-item__dot {
    border: 1px solid #451646 !important;
    margin-top: 18px;
    & > div {
      background-color: white !important;
    }
  }
}
@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }
    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }
      span:first-child {
        font-size: 13px !important;
      }
    }
    .v-timeline-item__dot {
      height: 14px !important;
      width: 14px !important;
    }
    .v-timeline-item__inner-dot {
      display: none !important;
    }
  }
}
</style>
