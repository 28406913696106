<template>
  <v-row style="margin: 20px">
    <v-card style="width: 100%">
      <v-card-title style="display: flex; justify-content: space-between">
        <span>تفاصيل التذكرة</span>
        <v-icon
          color="#3F0E40"
          @click="$store.state.openTasksDetailsRequestSection = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <page-component
          style="position: relative"
          :loading="loading"
          class="ticket-details-page"
        >
          <template #content>
            <div class="ticketContainer">
              <v-sheet class="eye-dad">
                <v-card
                  elevation="0"
                  style="
                    height: 56px;
                    margin-top: 20px;
                    border: 2px solid #e0e4e9 !important;
                    border-radius: 4px !important;
                  "
                >
                  <v-card-title class="py-2 d-flex align-center justify-center">
                    {{ ticket_details.customer ? ticket_details.customer.name : "" }}
                    <svg
                      @click="
                        $router.push({
                          name: 'request_details',
                          params: { id: ticket_details.req_id },
                        })
                      "
                      class="mr-3 mt-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.1"
                      height="11.982"
                      viewBox="0 0 16.1 11.982"
                      style="
                        background: #40be72;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        padding: 7px;
                        cursor: pointer;
                      "
                    >
                      <g
                        id="Group_11443"
                        data-name="Group 11443"
                        transform="translate(-765.437 -394.636)"
                      >
                        <g
                          id="Icon_feather-eye"
                          data-name="Icon feather-eye"
                          transform="translate(765.937 395.136)"
                        >
                          <path
                            id="Path_3993"
                            data-name="Path 3993"
                            d="M1.5,11.491S4.246,6,9.05,6s7.55,5.491,7.55,5.491-2.746,5.491-7.55,5.491S1.5,11.491,1.5,11.491Z"
                            transform="translate(-1.5 -6)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_3994"
                            data-name="Path 3994"
                            d="M17.618,15.559A2.059,2.059,0,1,1,15.559,13.5,2.059,2.059,0,0,1,17.618,15.559Z"
                            transform="translate(-8.009 -10.068)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </g>
                    </svg> 


                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          height="30"
                          color="transparent"
                          depressed
                          fab
                          light
                          v-bind="attrs"
                          v-on="on"
                          style="margin-left: -10px; position: absolute; left: 15px; bottom: 5px"
                        >
                          <v-icon color="#3A5DE6" size="40">mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item  @click="to_request_details(ticket_details.req_id)">
                          <v-list-item-icon class="ml-3">
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>
                            فتح الطلب
                          </v-list-item-title>
                        </v-list-item>

                        <router-link
                          style="text-decoration: none"
                          target="_blank"
                          :to="{ name: 'request_details', params: { id: ticket_details.req_id } }"
                        >
                          <v-list-item>
                            <v-list-item-icon class="ml-3">
                              <v-icon>mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                          </v-list-item>
                        </router-link>
                      
                      </v-list>
                    </v-menu>



                  </v-card-title>
                </v-card>
              </v-sheet>
              <v-timeline :dense="$vuetify.breakpoint.smAndDown" class="mb-10">
                <!-- First Card -->
                <span v-for="(item, i) in ticket_details.taskContentMany" :key="i">
                  <v-timeline-item>
                    <span style="color: #666666" slot="opposite" class="d-block">{{
                      item.date_of_content ? item.date_of_content.split(" ")[0] : ""
                    }}</span>
                    <span style="color: #666666" slot="opposite">{{
                      item.date_of_content ? item.date_of_content.split(" ")[1] : ""
                    }}</span>
                    <v-card
                      elevation="2"
                      width="341px"
                      class="cardo"
                      style="
                        box-shadow: unset !important;
                        background-color: #f4b5ff !important;
                        border-color: #ec62fb !important;
                        border-radius: 5px !important;
                      "
                    >
                      <v-card-title>
                        <div>{{ ticket_details.user.name }}</div>
                      </v-card-title>
                      <v-card-subtitle>
                        <div style="color: black !important">
                          {{ item.content }}
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-timeline-item>
                  <!-- Second Card -->
                  <v-timeline-item v-if="item.user_note">
                    <span style="color: #666666" slot="opposite" class="d-block">{{
                      item.date_of_note ? item.date_of_note.split(" ")[0] : ""
                    }}</span>
                    <span style="color: #666666" slot="opposite">{{
                      item.date_of_note ? item.date_of_note.split(" ")[1] : ""
                    }}</span>
                    <v-card
                      elevation="2"
                      width="341px"
                      class="cardo"
                      style="
                        box-shadow: unset !important;
                        color: white !important;
                        background-color: #922595 !important;
                        border-color: #992595 !important;
                        border-radius: 5px !important;
                      "
                    >
                      <v-card-title>
                        <div>{{ ticket_details.receive.name }}</div>
                      </v-card-title>
                      <v-card-subtitle>
                        <div style="color: white !important">
                          {{ item.user_note }}
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-timeline-item>
                </span>
                <span
                  class="ticket-status-msg"
                  :style="` margin: auto;
            width: 176px;
            height: 45px;
            display: block !important;
            margin-top: 25px !important;  
            min-height: unset !important;
            color: #40af6c;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 4px;
            z-index: 10;${
              ticket_details.status2 == 4
                ? 'background: #FF715B;color: #fff;border: 1px solid #FF715B;'
                : ticket_details.status2 == 5
                ? 'background: indianred;color: #fff;border: 1px solid #indianred;'
                : 'background: #f2fff7;color: #40af6c;border: 1px solid #40af6c;'
            }`"
                  v-if="
                    ticket_details.status2 == 3 ||
                    ticket_details.status2 == 4 ||
                    ticket_details.status2 == 5
                  "
                  >{{
                    ticket_details.status2 == 4
                      ? "التذكرة غير مكتملة"
                      : ticket_details.status2 == 4
                      ? "التذكرة مغلقة"
                      : "التذكرة مكتملة"
                  }}</span
                >
              </v-timeline>
              <v-card elevation="0" v-if="can_reply_on_task">
                <v-card-title class="ma-5 d-flex align-center justify-center"
                  >الرد علي ملاحظات المستلم داخل المهمة</v-card-title
                >
                <v-textarea
                  v-model="task_content"
                  class="mx-10 mb-5"
                  outlined
                ></v-textarea>
                <v-card-actions class="d-flex align-center justify-center"
                  ><v-btn
                    :loading="reply_loading"
                    color="primary"
                    @click="reply_ticket_content"
                    class="font-weight-bold"
                    >إرسال</v-btn
                  ></v-card-actions
                >
              </v-card>

              <v-card
                elevation="0"
                v-if="ticket_details.status2 == 0 || ticket_details.status2 == 1"
              >
                <v-card-actions class="d-flex align-center justify-center"
                  ><v-btn
                    :loading="reply_loading"
                    color="red"
                    @click="close_task"
                    class="font-weight-bold"
                  >
                    الغاء التذكرة</v-btn
                  ></v-card-actions
                >
              </v-card>

              <v-card elevation="0" v-if="ticket_details.status2 == 2">
                <v-card-actions class="d-flex align-center justify-center">
                  <v-btn
                    :loading="reply_loading"
                    color="green"
                    @click="mark_as_complete"
                    class="font-weight-bold"
                    >تحويل التذكرة لمكتمل</v-btn
                  >

                  <v-btn
                    :loading="reply_loading"
                    color="secondary"
                    @click="mark_as_incomplete"
                    class="font-weight-bold"
                    >تحويل التذكرة لغير مكتمل</v-btn
                  >
                </v-card-actions>
              </v-card>
            </div>
          </template>
        </page-component>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { axiosInstance } from "@/axios/config";

export default {
  data() {
    return {
      ticket_details: [],
      loading: false,
      reply_loading: false,
      task_content: "",
      complete_loading: false,
      incomplete_loading: false,
      close_loading: false,
      current_user: JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      ),
    };
  },

  created() {
    this.$store.state.opened = -1;
    if (this.$route.params.ticeket !== null) {
      this.get_request_tickets();
    }
    this.setBreadCrumbs(["الرئيسية", "التذاكر", "تفاصيل التذكرة"]);
  },

  computed: {
    can_reply_on_task() {
      return (
        (this.ticket_details["recive_id"] == parseInt(this.current_user["agent_id"]) &&
          !this.ticket_details.taskContentMany[
            this.ticket_details.taskContentMany.length - 1
          ].user_note &&
          this.ticket_details.status2 != 3 &&
          this.ticket_details.status2 != 4 &&
          this.ticket_details.status2 != 5) ||
        (this.ticket_details["user"].id == parseInt(this.current_user["agent_id"]) &&
          this.ticket_details.taskContentMany[
            this.ticket_details.taskContentMany.length - 1
          ].user_note &&
          this.ticket_details.taskContentMany[
            this.ticket_details.taskContentMany.length - 1
          ].content &&
          this.ticket_details.status2 != 3 &&
          this.ticket_details.status2 != 4 &&
          this.ticket_details.status2 != 5)
      );
    },
  },

  methods: {

    to_request_details(item) {
      this.$store.state.requestId = item;
      this.$router.push({
        name: "request_details",
        params: { id: item },
      });
    },

    get_request_tickets() {
      this.loading = true;
      axiosInstance
        // .get(`/agent/Task/Conversation/${this.$store.state.tasksDetailsRequestId}`)
        .get(`/agent/Task/Conversation/${this.$route.params.ticeket}`)
        .then((response) => {
          this.ticket_details = response.data.payload;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reply_ticket_content() {
      let data = {
        task_content_id: this.$route.params.ticeket,
        task_content: this.task_content,
      };
      this.reply_loading = true;
      axiosInstance
        .post("/agent/Task/Conversation/AddContent", data)
        .then(() => {
          this.showPopup("success", "تم الرد علي محتويات المهمة بنجاح");
          this.task_content = "";
          this.get_request_tickets();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.reply_loading = false;
        });
    },

    close_task() {
      this.close_loading = true;
      axiosInstance
        .put(`/agent/CancelTask/${this.$route.params.ticeket}`, {})
        .then(() => {
          this.showPopup("success", "تم إلغاء المهمة بنجاح");
          this.task_content = "";
          this.get_request_tickets();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.close_loading = false;
        });
    },

    mark_as_complete() {
      this.complete_loading = true;
      axiosInstance
        .put(`/agent/CompleteTask/${this.$route.params.ticeket}`, {})
        .then(() => {
          this.showPopup("success", "تم تحديد المهمة كمهمة مكتمله بنجاح");
          this.task_content = "";
          this.get_request_tickets();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.complete_loading = false;
        });
    },

    mark_as_incomplete() {
      this.incomplete_loading = true;
      axiosInstance
        .put(`/agent/InCompleteTask/${this.$route.params.ticeket}`, {})
        .then(() => {
          this.showPopup("success", "تم تحديد المهمة كمهمة غير مكتمله بنجاح");
          this.task_content = "";
          this.get_request_tickets();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.incomplete_loading = false;
        });
    },
  },

  watch: {
    "$store.state.tasksDetailsRequestId": {
      immediate: true,
      handler() {
        this.get_request_tickets();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-details-page {
  .v-timeline-item__dot {
    width: 40px !important;
    height: 150px !important;
    border-radius: unset !important;
    clip-path: polygon(
      0% 0%,
      50% 10%,
      100% 0%,
      100% 85%,
      50% 100%,
      0% 85%,
      0% 0%
    ) !important;
    // background: red !important;
    .v-timeline-item__inner-dot {
      display: none !important;
    }
  }
  .cardo {
    margin-top: 20px !important;
  }
  .v-timeline-item:nth-child(odd) {
    .v-timeline-item__dot {
      background: #f4b5ff !important;
      border: 1px solid #f4b5ff;
    }
    .cardo {
      margin-right: 13px;
      margin-left: auto !important;
      &::before,
      &::after {
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #f4b5ff !important;
        right: -41px !important;
      }
    }
  }
  .v-timeline-item:nth-child(even) {
    .v-timeline-item__dot {
      background: #922595 !important;
      border: 1px solid #922595;
    }
    .cardo {
      margin-left: 13px;
      margin-right: auto !important;
      &::before,
      &::after {
        border: none !important;
        width: 40px !important;
        height: 1px !important;
        background: #922595 !important;
        left: -41px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .ticket-details-page {
    .cardo {
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
    }
    .v-timeline-item:nth-child(even) {
      .cardo {
        margin-right: unset !important;
        margin-left: auto !important;
        margin-right: 13px !important;
        margin-left: 0 !important;
        &::before,
        &::after {
          right: -41px !important;
        }
      }
    }
    .ticket-status-msg {
      margin-right: 0 !important;
    }
  }
}
@media (max-width: 500px) {
  .ticket-details-page {
    .eye-dad {
      .v-card {
        padding: 10px !important;
      }
    }
  }
}
</style>
